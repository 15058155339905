//Package for type = 'date'
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import moment from "moment-jalaali";

const CharityInput = ({
  label,
  placeholder,
  type,
  onKeyDown,
  onChangeHandler,
  target,
  error,
}) => {
  moment.loadPersian({ usePersianDigits: false });

  if (type === "text" || type === "number" || type === "email") {
    return (
      <div className="relative flex flex-col items-start w-full gap-y-2">
        <label className="text-green-900">{label}</label>
        <input
          onKeyDown={onKeyDown && onKeyDown}
          className={`${
            error ? "!border-red-700" : ""
          } placeholder:text-right border-2 border-green-900 outline-none focus:!border-green-200 duration-200 rounded-md w-full h-12 p-3 bg-[#f5f5f5]`}
          type={type}
          placeholder={placeholder}
          onChange={(e) => onChangeHandler(target, e.target.value)}
        />
        {error && (
          <span className="text-red-700 text-sm font-semibold">{error}</span>
        )}
      </div>
    );
  } else if (type === "date") {
    return (
      <div className="flex flex-col items-start w-full gap-y-1">
        <label>{label}</label>
        <DatePicker
          placeholder={placeholder}
          onChange={(e) =>
            onChangeHandler(
              target,
              moment(e.toDate?.().toString()).format("YYYY-MM-DD")
            )
          }
          calendar={persian}
          locale={persian_fa}
        />
        {error && (
          <span className="text-red-700 text-sm font-semibold">{error}</span>
        )}
      </div>
    );
  }
};

export default CharityInput;
