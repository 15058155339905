import useFetch from "./../hooks/useFetch";
const endPoint = "committees";

export async function GetAllCommittee() {
  const apiCall = await useFetch().get(endPoint);
  return apiCall;
}

export async function GetSingleCommittee({ id }) {
  const apiCall = await useFetch().get(`committees/${id}`);
  return apiCall;
}
