import React, { useState, useEffect } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//service
import { GetSingleCourse } from "../../../services/educationCourse";

//component
import CourseDetail from "../../../components/pages/educationCourses/singleEducationCourse/courseDetail";
import SharingSection from "../../../components/pages/educationCourses/singleEducationCourse/sharingSection";
import TagsSection from "../../../components/pages/educationCourses/singleEducationCourse/tagsSection";
import SingleCourseBanner from "../../../components/pages/educationCourses/singleEducationCourse/banner";
import VideoListSection from "../../../components/pages/educationCourses/singleEducationCourse/videosListSection";
import SingleCourseComments from "../../../components/pages/educationCourses/singleEducationCourse/singleCourseComments";
import LazyLoading from "../../../components/common/lazyLoading";
import LoadingSingleCourse from "../../../components/pages/educationCourses/singleEducationCourse/loadingSingleCourse";
import UsefullSection from "../../../components/pages/educationCourses/singleEducationCourse/usefullLinkSection";

// //need backend
// import RecomendedCourse from "../../../components/pages/educationCourses/singleEducationCourse/recomendedCourse";
// import RecomendedCourse from "../../../components/pages/singleEducationCourse/recomendedCourse";

//SVG
import { ReactComponent as PathIcon } from "./../../../assets/svg/pathIcon.svg";

const Education = () => {
  const { id } = useParams();

  const [course, setCourse] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    asycnGetSingleCourse();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const asycnGetSingleCourse = async () => {
    setIsLoading(true);
    try {
      const response = await GetSingleCourse({ id });
      if (response.status === 200) {
        setCourse(response.data.data);
      } else {
        console.log("error occure -> response : ", response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const returnLoadingCard = () => {
    return <LoadingSingleCourse />;
  };

  return (
    <>
      <div className="flex items-center gap-2 mt-10 mb-5 mx-5 text-sm lg:text-xl">
        <PathIcon />
        {isLoading ? (
          <span className="w-1/3 h-7 bg-gray-300 rounded-md animate-pulse"></span>
        ) : (
          <span>خانه / دوره های آموزشی / {course.title}</span>
        )}
      </div>
      <LazyLoading isLoading={isLoading} card={returnLoadingCard}>
        <div className="grid grid-cols-12 m-4 gap-3 mx-5 sm:mx-auto sm:w-full ">
          <div className="col-span-12 row-start-2 md:col-span-5 lg:col-span-4 md:row-start-1 ">
            <CourseDetail
              className={"md:block hidden"}
              author={course.author}
              caption={course.body}
              price={course.price}
              status={course.status}
              updated_at={course.updated_at}
              // videoCount={course.videos_count}
              discount={course.discount}
              // user_own_it={course.user_own_it}
              courseId={course.id}
            />
            <div className="flex flex-col-reverse md:flex-col ">
              {/* <div className="flex flex-col md:flex-col">
                <SharingSection />
                <TagsSection tags={course.tags} />
              </div> */}

              {/**
               * TODO:Create Component for Add to Favorite After Talk to backend !!!
               */}
              <button className="bg-[#F81E6B] border-2 border-[#F81E6B] hover:bg-transparent hover:text-[#F81E6B] duration-200  text-[#ffffff] w-full rounded-md p-5 md:my-6 ">
                افزودن به دوره های مورد علاقه
              </button>
            </div>
            <UsefullSection />
          </div>
          <div className="col-span-12 h-fit md:col-span-7 lg:col-span-8 ">
            <CourseDetail
              className={"md:hidden block mb-5"}
              author={course.author}
              duration={course.duration}
              level={course.level}
              price={course.price}
              status={course.status}
              updated_at={course.updated_at}
              videoCount={course.videoCount}
              discount={course.discount}
              user_own_it={course.user_own_it}
              courseId={course.id}
            />
            <SingleCourseBanner banner={course.banner} body={course.body} />
            {/* <VideoListSection
              seasons={course.seasons}
              duration={course.duration}
            /> */}

            {/**
             * TODO:Fix it After Added from Backend
             */}
            {/* <RecomendedCourse /> */}

            {/* <SingleCourseComments /> */}
          </div>
        </div>
      </LazyLoading>
    </>
  );
};

export default Education;
