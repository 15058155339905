import React from "react";

//SVG
import { ReactComponent as Square } from "./../../../../assets/svg/square.svg";
import { ReactComponent as User } from "./../../../../assets/svg/user.svg";
import { ReactComponent as Time } from "./../../../../assets/svg/time.svg";
import money from "./../../../../assets/image/money.png";

//hooks
import PriceConvertor from "../../../../hooks/usePrice";
import TimeConverter from "../../../../hooks/useTime";
import { Link } from "react-router-dom";

const EducationCard = ({
  title,
  price,
  discount,
  author,
  banner,
  id,
  body,
}) => {
  return (
    <div className="w-[280px] border-2 border-green-900 place-content-center place-items-center mx-auto sm:w-[300px] flex flex-col  bg-[#f5f5f5] rounded-xl h-full w-full overflow-hidden">
      <div className="flex justify-center relative p-3">
        {discount !== 0 && (
          <span className="absolute group-hover:-left-3 -left-6 top-5 bg-[#FF0000] px-4 rounded-r-xl text-white duration-200">
            {discount}%
          </span>
        )}
        <div className="w-full h-[175px] rounded-md">
          <Link to={`/educationCourses/${id}`}>
            <img
              className="w-full h-full object-fill rounded-md"
              src={banner}
              alt="education course banner"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="font-bold mt-3 text-green-900 pb-3 hover:text-green-200 duration-200">
          <Link to={`/educationCourses/${id}`}>{title}</Link>
        </div>
        <div className="w-11/12 h-[0.5px] bg-[#dee2e6] rounded-full"></div>
        <div className=" mt-3 text-[#aaa] p-3 pt-1 text-center h-[105px] w-[270px] text-ellipsis overflow-hidden ">
          <Link to={`/educationCourses/${id}`}>{body}</Link>
        </div>
      </div>
      <div className="grid grid-cols-2 w-full border-t-2 border-green-900 self-end h-[50px]"
      /* style={{boxShadow:'10px 10px 16px -2px #ccc,-10px -10px 16px -2px #fff'}} */>
        <div className="flex p-2 shadow-inner w-full items-center justify-self-start gap-2 text-[#ccc]">
          <User />
          <span>{author}</span>
        </div>
        <div className="flex p-2 w-full shadow-inner flex-row-reverse justify-between justify-self-end">
          {/* <div className="flex items-center gap-2 text-[#31ADE4]">
            {<Time />}
            {<TimeConverter seconds={duration} />}
          </div> */}
          <div className="flex gap-2 text-green-200 place-content-center place-items-center">
            <img src={money} className="w-5" />
            <PriceConvertor discount={discount !== 0 && discount} price={price} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationCard;
