import React, { useEffect, useState } from "react";

//Components
import NewsListCard from "../../../components/pages/news/newsListCard";
import Pagination from "../../../components/common/pagination";
import LoadingNewsListCard from "../../../components/pages/news/loadingNewsListCard";
import LazyLoading from "../../../components/common/lazyLoading";
//SVG
import { ReactComponent as LeftArrow } from "./../../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "./../../../assets/svg/home-2.svg";
import { ReactComponent as DotsGroup } from "./../../../assets/svg/Group.svg";

//service
import { GetAllNews } from "../../../services/news";

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    asyncGetNewsList();
  }, []);

  const asyncGetNewsList = async () => {
    setIsLoading(true);
    try {
      const response = await GetAllNews();

      //check repsonse status
      if (response.status === 200) {
        setNews([...response.data.data]);
      } else {
        //error occure
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const generaList = () => {
    return (
      Array.isArray(news) &&
      news.length > 0 &&
      news.map((newsItem, index) => (
        <NewsListCard
          key={newsItem.id}
          id={newsItem.id}
          createdAt={newsItem.created_at}
          image={newsItem.image}
          title={newsItem.title}
          description={newsItem.slug}
        />
      ))
    );
  };

  console.log("NewsList : ", news);

  const loadingCard = () => {
    return <LoadingNewsListCard />;
  };

  return (
    <div>
      <div className="flex items-center gap-3 mr-7 -mt-[7rem]">
        <Home />
        <LeftArrow />
        <span>اخبار</span>
      </div>
      <div className="flex items-center gap-3 mr-7 mt-10 mb-16">
        <DotsGroup />
        <span className="text-[#334155] font-bold text-3xl">لیست اخبار</span>
      </div>
      <div className="grid grid-cols-12 gap-y-10">
        <LazyLoading
          card={loadingCard}
          isLoading={isLoading}
          numberRepetation={6}
        >
          {generaList()}
        </LazyLoading>
      </div>
      <div className="mt-10 mx-3">{/* <Pagination /> */}</div>
    </div>
  );
};

export default NewsList;
