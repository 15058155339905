import React from "react";

//SVG
import { ReactComponent as Facebook } from "./../../../../assets/svg/facebook.svg";
import { ReactComponent as Instagram } from "./../../../../assets/svg/instagram.svg";
import { ReactComponent as Telegram } from "./../../../../assets/svg/telegram.svg";
import { ReactComponent as Twitter } from "./../../../../assets/svg/twitter.svg";

//PNG
import Samandehi from "./../../../../assets/svg/samandehi.png";
import Enamad from "./../../../../assets/svg/enamad.png";
import Logo from './../../../../assets/image/logo.png'
import { Link } from "react-router-dom";
const DownFooter = () => {
  return (
    <div className="w-full mx-auto bg-green-900 flex flex-col items-center py-2 text-right gap-1 mt-2">
      <div className="flex flex-col text-[#ccc] text-center text-xs md:text-sm lg:text-base">
        <span className="pb-1">
          تمامی حقوق این سایت متعلق به گروه جهادی شهید حججی می باشد
        </span>
        {/* <span>ساخته شده با ❤️ در گروه جهادی شهید حججی</span> */}
      </div>
      <div className="flex">
        <span>
          <a href="https://instagram.com/heiat_mohebbin_ahlebait?igshid=MzNlNGNkZWQ4Mg==">
            <Instagram className="text-[#ccc] hover:text-[#dad7cd]" />
          </a>
        </span>

        <span className="px-10">
          <a href="https://t.me/jahadihojaji">
            <Telegram className="text-[#ccc] hover:text-[#dad7cd]" />
          </a>
        </span>
        <span>
          <a href="https://instagram.com/shahidhojajii?igshid=NGExMmI2YTkyZg==">
            <Facebook className="text-[#ccc] hover:text-[#dad7cd]" />
          </a>
        </span>
        <span className="pr-10">
          <a href="https://t.me/hmohebbin">
            <Twitter className="text-[#ccc] hover:text-[#dad7cd]" />
          </a>
        </span>
      </div>
    </div>
  );
};

export default DownFooter;
