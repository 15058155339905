import React, { useState, useEffect } from "react";

//react-router-dom
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

import moment from "moment-jalaali";

//cookie
import { useCookies } from "react-cookie";

//service
import { GetSingleOrder } from "../../services/order";

//hook
import PriceConvertor from "../../hooks/usePrice";
import TimeConverter from "../../hooks/useTime";

//component
import LoadingLayout from "../../components/common/loadingLayout";

// SVG
// import { ReactComponent as Camera } from "./../../assets/svg/camera.svg";
import { ReactComponent as User } from "./../../assets/svg/user.svg";
import { ReactComponent as Condition } from "./../../assets/svg/condition.svg";
import { ReactComponent as DateSvg } from "./../../assets/svg/date.svg";
import { ReactComponent as Level } from "./../../assets/svg/level.svg";
import { ReactComponent as Time } from "./../../assets/svg/timee.svg";

const Invoice = () => {
  moment.loadPersian({ usePersianDigits: true });
  const [cookies] = useCookies(["token"]);
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState({});

  useEffect(() => {
    onGetOrderHandler();
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const onGetOrderHandler = async () => {
    setIsLoading(true);
    try {
      const response = await GetSingleOrder({
        token: cookies.token,
        orderId: searchParams.get("id"),
      });

      if (response.status === 200) {
        setOrder(response.data);
        setIsLoading(false);
      } else {
        //an error occurre
        console.log("response : ", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center flex-col gap-y-5 w-full mt-10 px-5 sm:px-0">
      <span
        className={`text-2xl font-semibold ${
          searchParams.get("status") === "not-verified"
            ? "text-red-800"
            : "text-green-800"
        } text-center`}
      >
        {searchParams.get("status") === "not-verified"
          ? "پرداخت انجام نشده"
          : "پرداخت موفق بود"}
      </span>
      <div className="flex flex-col lg:flex-row gap-3 w-full">
        <div className="flex flex-col items-start justify-center gap-y-5 bg-white p-2 sm:p-5 rounded-md font-medium text-sm lg:text-base w-full">
          <LoadingLayout isLoading={isLoading}>
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-center gap-2">
              <span className="text-[#B0B9BE]">شناسه تراکنش : </span>
              <span className="!text-[10px] sm:!text-sm text-[#2D3A6E] font-medium">
                {order.payments && order.payments[0].transaction_id}
              </span>
            </div>
            <div className="flex items-center justify-center gap-x-2">
              <span className="text-[#B0B9BE]">شناسه ارجاع : </span>
              <span className="text-[#2D3A6E] font-medium">
                {order.payments && order.payments[0].reference_id
                  ? order.payments[0].reference_id
                  : "شناسه ای ثبت نشده است"}
              </span>
            </div>
            <div className="flex items-center justify-center gap-x-2">
              <span className="text-[#B0B9BE]">مقدار : </span>
              {order.payments && (
                <PriceConvertor
                  priceClass={"text-[#2D3A6E]"}
                  typeClass={"text-[#2D3A6E]"}
                  price={+order.payments[0].amount}
                />
              )}
            </div>
            <div className="flex items-center justify-center gap-x-2">
              <span className="text-[#B0B9BE]">زمان تراکنش : </span>
              <span className="text-[#2D3A6E] font-medium">
                {moment(order.payments && order.payments[0].created_at).format(
                  " hh:mm , jYYYY/jMM/jDD "
                )}
              </span>
            </div>
          </LoadingLayout>
        </div>
        <div className="flex flex-col items-start gap-y-2 bg-white p-5 rounded-md text-base lg:text-lg w-full">
          <LoadingLayout isLoading={isLoading}>
            <span className="text-xl font-semibold text-[#2D3A6E] self-center mb-2">
              {order.course && order.course.title}
            </span>
            <div className="flex gap-y-2 flex-col sm:flex-row">
              <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
                <User classNam e="text-[#B0B9BE]" />
                <span>مدرس دوره : </span>
              </div>
              <span className="text-[#2D3A6E] font-medium">
                {order.course && order.course.author}
              </span>
            </div>
            <div className="flex gap-y-2 flex-col sm:flex-row">
              <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
                <Time />
                <span> مدت زمان دوره : </span>
              </div>
              {/* <TimeConverter
                className={"text-[#2D3A6E] font-medium"}
                seconds={order.course && order.course.duration}
              /> */}
              <span>{order.course && order.course.duration}</span>
            </div>
            <div className="flex gap-y-2 flex-col sm:flex-row">
              <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
                <Level />
                <span> سطح دوره : </span>
              </div>
              <span className="text-[#2D3A6E] font-medium">
                {order.course && order.course.level}
              </span>
            </div>
            <div className="flex gap-y-2 flex-col sm:flex-row">
              <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
                <Condition />
                <span> وضعیت دوره : </span>
              </div>
              <span className="text-[#31ADE4] font-medium">
                {order.course && order.course.status}
              </span>
            </div>
            <div className="flex gap-y-2 flex-col sm:flex-row">
              <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
                <DateSvg />
                <span> تاریخ اخرین بروزرسانی : </span>
              </div>
              <span className="text-[#2D3A6E] font-medium">
                {moment(order.course && order.course.updated_at).format(
                  "jYYYY/jMM/jDD"
                )}
              </span>
            </div>
            <Link
              to={`/educationCourses/${order.course && order.course.id}`}
              className={
                "px-4 py-2 border-2 border-[#6FC341] hover:bg-white hover:text-[#6FC341] duration-200 bg-[#6FC341] text-white rounded-md self-end"
              }
            >
              {searchParams.get("status") === "not-verified"
                ? "مشاهده دوره"
                : " ورود به دوره"}
            </Link>
          </LoadingLayout>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
