import { useState, useEffect } from "react";
import { toast } from "react-toastify";
//cookie
import { useCookies } from "react-cookie";
//react-router-dom
import { useNavigate } from "react-router-dom";
//redux
import { useSelector } from "react-redux";
//service
import { VerifyCode } from "../../../../../services/auth";
//component
import LoadingBtn from "../../../../common/loadingBtn";
import useTimer from "./../../../../../hooks/useTimer";
// SVG
import { ReactComponent as Back } from "./../../../../../assets/svg/Vector.svg";

const SignUpCOde = ({ stepForwardHandler, stepBackwardHandler }) => {
  const [cookies, setCookies] = useCookies(["token"]);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state);

  const { seconds, minutes } = useTimer(120);

  const [isLoading, setIsLoading] = useState(false);
  const [nums, setNums] = useState({
    num1: "",
    num2: "",
    num3: "",
    num4: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    //check if timer go to zero redirect to add phone number again
    if (+seconds === 0 && +minutes === 0) {
      stepBackwardHandler();
    }
  }, [seconds, minutes]);

  useEffect(() => {
    let emptyFlag = false;
    for (const key in nums) {
      if (nums[key] === "") {
        emptyFlag = true;
      }
    }

    if (emptyFlag === false) {
      asyncPostCode();
    }
  }, [nums]);

  const onChangeHandler = (e, target) => {
    //check input to be number
    if (!/^\d+$/.test(e.target.value) && e.target.value !== "") {
      //it's not number return
      return;
    }

    setError("");

    if (e.target.value.length <= 1) {
      setNums({
        ...nums,
        [target]: e.target.value,
      });
    }

    const prevSibling = e.target.previousSibling;
    const nextSibling = e.target.nextSibling;

    if (e.target.value.length > 0) {
      if (nextSibling === null) {
        document.activeElement.blur();
      } else nextSibling.focus();
    } else {
      if (prevSibling !== null) {
        prevSibling.focus();
      }
    }
  };

  const postCodeHandler = () => {
    if (
      nums.num1.length === 0 ||
      nums.num2.length === 0 ||
      nums.num3.length === 0 ||
      nums.num4.length === 0
    ) {
      setError("لطفا فیلد ها را پر کنید");
    } else {
      asyncPostCode();
    }
  };

  const asyncPostCode = async () => {
    setIsLoading(true);

    try {
      const codeNumber = `${nums.num1}${nums.num2}${nums.num3}${nums.num4}`;
      const resposne = await VerifyCode({
        codeNumber,
        phoneNumber: user.phoneNumber,
      });
      if (resposne.status === 200) {
        if (resposne.data.redirect === "register") {
          stepForwardHandler();
        } else {
          setCookies("token", resposne.data.token);
          toast.success("با موفقیت وارد شدید");
          navigate("/");
        }
      } else {
        setError("کد تایید نشد");
        console.log("response : ", resposne);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const moverCursorPointertoEnd = (e) => {
    const end = e.target.value.length;
    e.target.setSelectionRange(end, end);
  };

  return (
    <div className="">
      <button
        onClick={stepBackwardHandler}
        className="px-3 py-2 rounded bg-[#F5F6FA] border-2 border-[#F5F6FA] hover:bg-white duration-200"
      >
        <Back />
      </button>
      <span className="font-bold flex felx-row justify-center">
        تایید شماره تلفن
      </span>
      <div className="flex flex-col items-start">
        <p className="py-4 flex-col self-center lg:text-sm text-[.8rem]">
          کد {(4).toLocaleString("fa")} رقمی ارسال شده به موبایل خود را وارد
          کنید
        </p>
        <span className="py-1 px-3 mb-8 flex-col self-center bg-neutral-200 text-sm rounded">
          {user.phoneNumber}
        </span>
        <form className="flex flex-row-reverse w-full max-w-[360px] mx-auto gap-3">
          <input
            type="text"
            maxLength={1}
            className={` ${
              error && "!border-red-700"
            } w-full h-[60px] border-2 border-gray-300 rounded text-center text-2xl`}
            value={nums.num1}
            onClick={moverCursorPointertoEnd}
            onChange={(e) => onChangeHandler(e, "num1")}
          />
          <input
            type="text"
            inputMode="numeric"
            maxLength={1}
            className={` ${
              error && "!border-red-700"
            } w-full h-[60px] border-2 border-gray-300 rounded text-center text-2xl`}
            value={nums.num2}
            onClick={moverCursorPointertoEnd}
            onChange={(e) => onChangeHandler(e, "num2")}
          />
          <input
            type="text"
            inputMode="numeric"
            maxLength={1}
            className={` ${
              error && "!border-red-700"
            } w-full h-[60px] border-2 border-gray-300 rounded text-center text-2xl`}
            value={nums.num3}
            onClick={moverCursorPointertoEnd}
            onChange={(e) => onChangeHandler(e, "num3")}
          />
          <input
            type="text"
            inputMode="numeric"
            maxLength={1}
            className={` ${
              error && "!border-red-700"
            } w-full h-[60px] border-2 border-gray-300 rounded text-center text-2xl`}
            value={nums.num4}
            onClick={moverCursorPointertoEnd}
            onChange={(e) => onChangeHandler(e, "num4")}
          />
        </form>
        {error && (
          <span className="mt-2 text-red-700 text-sm font-semibold">
            {error}
          </span>
        )}
      </div>
      <p className="flex flex-row justify-center pt-5">
        <span className="mx-2">زمان باقی مانده:</span>
        <span className="text-[#FF6D00]">
          {minutes}:{seconds}
        </span>
      </p>
      <LoadingBtn
        action={postCodeHandler}
        isLoading={isLoading}
        className={
          "bg-[#31ADE4] border-[#31ADE4] border-2 hover:text-[#31ADE4] hover:bg-white duration-200 font-medium"
        }
        text={"مرحله بعد"}
      />
    </div>
  );
};

export default SignUpCOde;
