const SingleCourseBanner = ({ banner, body }) => {
  return (
    <div className="bg-[#ffffff] p-6 rounded-md">
      <img
        src={banner}
        className="w-full rounded-sm h-[300px] lg:h-[450px] object-fill"
        alt="banner single course"
      />
      <p className="font-medium text-black mt-8">{body}</p>
    </div>
  );
};

export default SingleCourseBanner;
