import React, { useEffect } from "react";

import HomeNews from "../../components/pages/home/news";
import VoluntaryCooperation from "../../components/pages/home/VoluntaryCooperation";
import Hero from "../../components/pages/home/hero";
import Committees from "../../components/pages/home/committees";
import Authorities from "../../components/pages/home/authorities";
import CharityService from "../../components/pages/home/charityService";

const Home = () => {
  useEffect(() => {
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="bg-[#F5F5F5]">
      <Hero />
      <CharityService />
      <Committees />
      <Authorities />
      <HomeNews />
      <VoluntaryCooperation />
    </div>
  );
};

export default Home;
