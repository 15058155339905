import useFetch from "./../hooks/useFetch";
const endPoint = "orders";

export async function BuyCourse({ token, courseId }) {
  const apiCall = await useFetch().post(
    endPoint,
    {
      course_id: courseId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return apiCall;
}

export async function GetSingleOrder({ token, orderId }) {
  const apiCall = await useFetch().get(`${endPoint}/${orderId}`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });
  return apiCall;
}

export async function PayOrder({ token, orderId }) {
  const apiCall = await useFetch().get(`${endPoint}/pay/${orderId}`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });
  return apiCall;
}
