import React from "react";
import { Link } from "react-router-dom";

//SVG
import { ReactComponent as Location } from "./../../../../assets/svg/location.svg";
import { ReactComponent as Phone } from "./../../../../assets/svg/phone.svg";
import { ReactComponent as Instagram } from "./../../../../assets/svg/instagram.svg";
import { ReactComponent as Telegram } from "./../../../../assets/svg/telegram.svg";
// import { ReactComponent as Email } from "./../../../../assets/svg/email.svg";
// import { ReactComponent as Time } from "./../../../../assets/svg/time.svg";
import Logo from './../../../../assets/image/logo.png';
import Samandehi from "./../../../../assets/svg/samandehi.png";
import Enamad from "./../../../../assets/svg/enamad.png";

const UpFooter = () => {
  return (
    <div className="m-auto border-2 max-w-7xl w-11/12 text-green-900 rounded-xl text-right bg-[#f5f5f5] flex lg:justify-around lg:mt-5 lg:py-7 px-5 pb-5">
      <div className="grid grid-cols-1 pt-5 md:grid-cols-12 w-full sm:gap-5">
        <div className="flex md:block md:col-span-5">
          <div className="my-2 col-span-5 p-1 sm:my-0 lg:pt-5 rounded-xl w-full">
          <div className="flex gap-x-2 content-center items-center">
            <Link to={'/'}>
                <img className="w-9 h-9" src={Logo} alt="jahadi logo" />
              </Link>
              <span className="font-bold text-base sm:text-lg lg:text-xl text-green-900">
                راه‌های ارتباطی با گروه جهادی شهید حججی
              </span>
          </div>
            <div className="flex flex-col gap-4 pt-4 pb-2 text-xs md:text-sm lg:text-base lg:pt-10 lg:pb-0 lg:py-5 lg:items-start">
              {/* <span className="flex items-start justify-center lg:justify-start">
                <Location className="mx-1 lg:ml-2 lg:mx-0 lg:mt-1" />
                <span className="w-[80%] lg:w-[80%] xl:w-full">
                  نشانی: زعفرانیه، خیابان اعجازی، میدان اعجازی، طبقه فوقانی بانک
                  سرمایه، ساختمان آصف،‌طبقه ۱، واحد ۱
                </span>
              </span> */}
              <button
                onClick={() => {
                  window.location.href = "tel:+989123331147";
                }}
                className="flex items-center text-xs sm:text-base"
              >
                <Phone className="ml-2 text-[#dad7cd] sm:text-base" />
                شماره موبایل: 09123331174
              </button>
              <button
                className="flex items-center text-xs sm:text-base"
                onClick={() => {
                  window.location.href =
                    "https://www.instagram.com/shahidhojajii/";
                }}
              >
                <Instagram className="ml-2 text-[#dad7cd] w-4" />
                اینستاگرام : shahidhojajii@
              </button>
              <button
                onClick={() => {
                  window.location.href = "https://telegram.org/jahadihojaji/";
                }}
                className="flex items-center text-xs sm:text-base"
              >
                <Telegram className="ml-2 text-[#dad7cd] w-4" />
                تلگرام : jahadihojaji@
              </button>
            </div>
          </div>
          <div className="md:flex items-center gap-x-2">
        <span className="pl-10">
          <img src={Samandehi} alt="samandehi" />
        </span>
        <span>
          <img src={Enamad} alt="enamad" />
        </span>
      </div>
        </div>
        
        <div className="col-span-6 w-full flex flex-col lg:flex-row lg:justify-between lg:pt-5 xl:w-full gap-x-5">
          <div className=" p-1 lg:text-right lg:py-0 rounded-xl">
            <span className="font-bold text-base sm:text-lg lg:text-xl">
              لینک‌های مهم
            </span>
            <ul className="grid grid-cols-2 gap-2 text-xs sm:text-base sm:max-w-96 lg:block lg:pt-10 lg:items-start lg:list-disc mt-3">
              <li className="">درباره ما</li>
              <li className="lg:py-8 xl:py-7">تماس با ما</li>
              <li className="">ثبت شکایت</li>
              <li className="lg:py-8 xl:py-7">قوانین و مقررات</li>
              <li className="">توسعه دهندگان</li>
              {/* <button
                onClick={() => {
                  window.location.href =
                    "https://www.instagram.com/heiat_mohebbin_ahlebait/";
                }}
                className="flex items-center justify-start gap-2 font-medium text-base"
              >
                <Instagram className="text-[#FFB703] w-4 text-right" />
                <div>
                  <h4 className="flex justify-start justify-items-start">هیئت محبین اهل بیت :</h4>
                  <h4 style={{direction:'ltr'}}>@heiat_mohebbin_ahlebait</h4>
                </div>
              </button>
              <button className="flex items-center justify-start gap-2 text-base font-medium">
                <Telegram
                  onClick={() => {
                    window.location.href = "https://telegram.org/hmohebbin/";
                  }}
                  className="text-[#FFB703] w-4"
                />
                <div>
                  <h4>هیئت محبین اهل بیت :</h4>
                  <h4 style={{direction:'ltr'}}>@hmohebbin</h4>
                </div>
              </button> */}
            </ul>
          </div>
          <div className="hidden sm:block p-1 rounded-xl py-5 lg:text-right lg:pt-0">
            <span className="font-bold text-xl md:text-lg lg:text-xl">
              سایر
            </span>
            <ul className="grid grid-cols-2 gap-2 mt-2 text-base p-1 md:text-sm lg:block lg:pt-10 lg:text-base lg:items-start lg:list-disc">
              <li>مشارکت در فراخوان ها</li>
              <li className="py-2 lg:py-8 xl:py-7">سفارش بنر و محصولات</li>
              <li>فرم دریافت قلک مهربانی</li>
              <li className="py-2 lg:py-8 xl:py-7">فرم دریافت قلک درب بطری</li>
              <li>فرم دریافت باکس باتری</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpFooter;
