import useFetch from "./../hooks/useFetch";
// const endPoint = "";

//endPoint => /auth => justPhoneNumber
//endPoint => /

export async function PostPhoneNumber({ dataSchema }) {
  //reg-getphone
  const apiCall = await useFetch().post("auth", {
    phone_number: dataSchema,
    real: 123,
  });

  return apiCall;
}

export async function VerifyCode({ phoneNumber, codeNumber }) {
  const apiCall = await useFetch().post("verify", {
    phone_number: phoneNumber,
    verification_code: codeNumber,
  });

  return apiCall;
}

export async function PostUserData({
  firstName,
  lastName,
  email,
  birthDay,
  phoneNumber,
  nationalCode,
  alms,
  committees,
  userType,
  city
}) {

  const formData = new FormData()

  formData.append("first_name" , firstName)
  formData.append("last_name" , lastName)
  
  if(email.trim().length !== 0){
    formData.append("email" , email)
  }

  if(nationalCode.trim().length !== 0){
    formData.append("national_code" , nationalCode)
  }

  formData.append("phone_number" , phoneNumber)
  formData.append("birthday" , birthDay)
  formData.append("alms" , alms)
  formData.append("committees" , committees)
  formData.append("user_type" , userType)
  formData.append("city_id" , city)


  //reg-getinfo
  const apiCall = await useFetch().post("users", formData);

  return apiCall;
}


export async function GetCommitteeList(){
  const apiCall = await useFetch().get("user/committees");
  return apiCall 
}