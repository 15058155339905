import React, { useState, useEffect } from "react";
//moment
import moment from "moment-jalaali";
//react-router-dom
import { useParams, Link } from "react-router-dom";
//service
import { GetSingleNews } from "../../../services/news";
//component
import LazyLoading from "../../../components/common/lazyLoading";
import LoadingSingleNews from "../../../components/pages/news/loadingSingleNews";
//SVG
import { ReactComponent as LeftArrow } from "./../../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "./../../../assets/svg/home-2.svg";
import { ReactComponent as Share } from "./../../../assets/svg/share.svg";
import { ReactComponent as Twitter } from "./../../../assets/svg/twitter.svg";
import { ReactComponent as Telegram } from "./../../../assets/svg/telegram.svg";
import { ReactComponent as Facebook } from "./../../../assets/svg/facebook.svg";
import { ReactComponent as LinkSVG } from "./../../../assets/svg/link.svg";
import { ReactComponent as Coment } from "./../../../assets/svg/coment.svg";

//PNG
import imagenNews from "./../../../assets/image/singlenews.png";

import Comment from "../../../components/pages/news/comment";

const commentData = [
  {
    id: 1,
    name: "علی الهیارلو",
    date: "2ماه پیش ",
    text: " با سلام و احترام از شما یاور نیک‌اندیش که با مراجعه به وبسایت محک همراه بیش از ۲۰ هزار کودک مبتلا به سرطان در حال درمان شده‌اید، صمیمانه سپاسگزاریم و قدردان مهر شما و یاوران عزیزمان هستیم. سپاس",
    reply: [
      {
        id: 3,
        name: "علی الهیارلو",
        date: "2ماه پیش ",
        text: " با سلام و احترام از شما یاور نیک‌اندیش که با مراجعه به وبسایت محک همراه بیش از ۲۰ هزار کودک مبتلا به سرطان در حال درمان شده‌اید، صمیمانه سپاسگزاریم و قدردان مهر شما و یاوران عزیزمان هستیم. سپاس",
      },
      {
        id: 4,
        name: "علی الهیارلو",
        date: "2ماه پیش ",
        text: " با سلام و احترام از شما یاور نیک‌اندیش که با مراجعه به وبسایت محک همراه بیش از ۲۰ هزار کودک مبتلا به سرطان در حال درمان شده‌اید، صمیمانه سپاسگزاریم و قدردان مهر شما و یاوران عزیزمان هستیم. سپاس",
      },
    ],
  },
  {
    id: 2,
    name: "علی الهیارلو",
    date: "2ماه پیش ",
    text: " با سلام و احترام از شما یاور نیک‌اندیش که با مراجعه به وبسایت محک همراه بیش از ۲۰ هزار کودک مبتلا به سرطان در حال درمان شده‌اید، صمیمانه سپاسگزاریم و قدردان مهر شما و یاوران عزیزمان هستیم. سپاس",
    reply: [],
  },
];

const SingleNews = () => {
  moment.loadPersian({ usePersianDigits: true });

  const { id } = useParams();
  const [news, setNews] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUserCopy, setIsUserCopy] = useState(false);

  useEffect(() => {
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    asycnGetSingleNews();
  }, []);

  const asycnGetSingleNews = async () => {
    setIsLoading(true);
    try {
      const response = await GetSingleNews({ id });
      //check response status
      if (response.status === 200) {
        setNews({
          ...response.data.data,
        });
      } else {
        //an error occure
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href).then(function () {
      setIsUserCopy(true);
      setTimeout(() => {
        setIsUserCopy(false);
      }, [5000]);
    });
  };

  const loadingCard = () => {
    return <LoadingSingleNews />;
  };

  return (
    <LazyLoading isLoading={isLoading} card={loadingCard} numberRepetation={1}>
      <div className="flex items-center gap-2 sm:gap-3 mr-4 sm:mr-0 -mt-[7rem]">
        <Link to={"/"}>
          <Home />
        </Link>
        <LeftArrow />
        <Link to={"/news"}>
          <span className="text-sm sm:text-lg">اخبار</span>
        </Link>
        <LeftArrow />
        <span className="text-[#B0B9BE] text-[12px] sm:text-lg ">
          {news.slug}{" "}
        </span>
      </div>
      <div className="flex flex-col place-content-center place-items-center gap-3 mt-10 sm:mr-0">
        <span className="text-green-900 w-3/4 text-center mx-auto font-bold sm:text-3xl text-l py-3 px-7 rounded-xl"
        style={{boxShadow:'inset 4px 4px 6px #ccc,inset -4px -4px 6px #fff'}}>
          {news.title}{" "}
        </span>
        <div className="flex gap-3 my-3 text-green-900 text-sm sm:text-md sm:mr-0 py-3 px-5 rounded-xl"
        style={{boxShadow:'inset 4px 4px 6px #ccc,inset -4px -4px 6px #fff'}}>
        <div className="border-l-2 border-[#ccc] sm:pl-3 pl-1">
          {moment(news.created_at).format("jDD jMMMM jYYYY , HH:mm")}
        </div>
        <div className="border-l-2 border-[#ccc] sm:pl-3 pl-1 ">
          <span>کد خبر:</span>
          <span>588325</span>
        </div>
        <div>
          <span>نظرات :</span>
          <span>0</span>
        </div>
      </div>
      </div>
      
      <div className="shadow-md">
        {<div>
          <img
            className="w-1/2 max-w-[500px] mx-auto"
            src={news.image}
            alt="news"
          />
        </div>}
        <div className="py-8 px-6 text-lg sm:text-xl max-w-6xl mx-auto">
        <p
            className="leading-10 text-green-900 font-medium p-5 rounded-xl"
            style={{boxShadow:'inset 4px 4px 6px #ccc,inset -4px -4px 6px #fff'}}
            dangerouslySetInnerHTML={{ __html: news.description }}
          ></p>
          {/* <div className="flex flex-col gap-6 text-lg sm:text-xl">
            <span className="text-[#112D4E] ">بیشتر بخوانید:</span>
            <span className="text-[#3F72AF]">
              برگزاری بیست‌وچهارمین جلسه کلاس آموزش مدیریت اسلامی
            </span>
            <span className="text-[#3F72AF]">
              نخستین دوره اعزام دبیر به بشاگرد برای برگزاری آموزش حضوری در سال
              تحصیلی ۱۴۰۱-۱۴۰۲
            </span>
            <span className="text-[#3F72AF]">
              بزرگداشت روز دانشجو با حضور دانشجویان بشاگردی شاغل به تحصیل در
              تهران و دانشجویان فعال عضو گروه جهادی
            </span>
          </div> */}
          <div className=" my-14 p-7 rounded-md"
           style={{boxShadow:'inset 4px 4px 6px #ccc,inset -4px -4px 6px #fff'}}>
        <div className="flex flex-row border-b-2  p-2  border-[#B0B9BE]">
          <Share className="text-[#fca311] text-xl ml-2" />
          <span className="text-green-900">اشتراک گذاری</span>
        </div>
        <div className="flex flex-col-reverse gap-4 sm:flex-row sm:gap-0 justify-between mt-5 items-center">
          <div className="flex gap-7 items-center">
            <Twitter className="text-[#fca311]"/>
            <Facebook className="text-[#fca311]"/>
            <Telegram className="text-[#fca311]"/>
          </div>
          <div className="relative group">
            <button onClick={copyToClipboard} className="flex">
              <span>{window.location.href}</span>
              <LinkSVG className="mx-2" />
            </button>
            <span
              className={`hidden group-hover:block absolute px-3 py-1 text-white ${
                isUserCopy ? "bg-green-700" : "bg-black"
              } duration-200 bg-opacity-70 rounded-lg`}
            >
              {isUserCopy ? "ادرس کپی شده" : "برای کپی کلیک کنید"}
            </span>
          </div>
        </div>
      </div>
        </div>
      </div>
      {/* <div className="bg-[#FFF] my-14 p-7 rounded-md shadow-md ">
        <div className="flex flex-row border-b-2  p-2  border-[#B0B9BE]">
          <span className="text-[#B0B9BE] text-xl ml-2">#</span>
          <span className="text-[#000]">برچسب ها</span>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5 mt-5">
          <span className="bg-[#EEEEEE] rounded-sm px-5 py-2 text-[#475466] ">
            دوره اموزشی
          </span>
          <span className="bg-[#EEEEEE] rounded-sm px-5 py-2 text-[#475466] ">
            دوره اموزشی
          </span>
          <span className="bg-[#EEEEEE] rounded-sm px-5 py-2 text-[#475466] ">
            دوره اموزشی
          </span>
          <span className="bg-[#EEEEEE] rounded-sm px-5 py-2 text-[#475466] ">
            دوره اموزشی
          </span>
        </div>
      </div> */}
      
      {/* <div className="bg-[#FFF] my-14 p-3 sm:p-7 rounded-md shadow-md ">
        <div className="flex flex-row border-b-2  p-2  border-[#B0B9BE]">
          <Coment className="text-[#B0B9BE] text-xl ml-2" />
          <span className="text-[#000]">نظرات</span>
        </div>

        {true ? (
          <span className="md:text-xl text-md text-center flex self-center justify-center text-[#475466] mt-5">
            هیچ دیدگاهی برای این خبر ثبت نشده است. شما اولین نفر باشید!.
          </span>
        ) : (
          commentData.map((comment, index) => (
            <>
              <div
                className={` ${
                  comment.reply.length !== 0
                    ? `border-2  mt-5 rounded-md p-2  border-[#B0B9BE]`
                    : ""
                }`}
              >
                <Comment
                  styl={""}
                  key={index}
                  id={comment.id}
                  name={comment.name}
                  date={comment.date}
                  text={comment.text}
                />
                {comment.reply.length === 0 ? (
                  <></>
                ) : (
                  comment.reply.map((reply, index) => (
                    <Comment
                      styl={"sm:mr-10 mr-5"}
                      key={index}
                      id={reply.id}
                      name={reply.name}
                      date={reply.date}
                      text={reply.text}
                    />
                  ))
                )}
              </div>
            </>
          ))
        )}
      </div>
      <div className="bg-[#FFF] py-5 px-4 md:px-20 rounded-md shadow-md ">
        <div className="flex justify-center flex-col items-center">
          <span className="border-b-2 border-[#3F72AF] w-full text-center py-2 mb-2 ">
            ارسال نظر
          </span>
          <span className="text-[#475466] text-lg my-5">
            آدرس ایمیل شما منتشر نخواهد شد
          </span>
        </div>
        <div className="flex flex-col items-center my-3 gap-5 py-3 px-5 w-full">
          <textarea
            rows="10"
            placeholder="نظر شما..."
            className="placeholder:text-sm border-2 border-[#B0B9BE]  rounded-md w-full p-3"
          />
          <div className="flex flex-col gap-8 lg:gap-24 lg:flex-row w-full">
            <input
              type={"text"}
              placeholder="نام و نام خانوادگی خود را وارد کنید"
              className="placeholder:text-sm border-2 border-[#B0B9BE]  rounded-md h-10 w-full p-3"
            />
            <input
              type={"text"}
              placeholder="ایمیل خود را وارد کنید"
              className="placeholder:text-sm border-2 border-[#B0B9BE]  rounded-md h-10 w-full p-3"
            />
          </div>
          <button className="px-7 py-2 self-end w-full text-[#FFF] lg:w-fit bg-[#3F72AF] border-2 border-[#3F72AF] hover:text-[#3F72AF] hover:bg-white duration-200 rounded-md">
            ارسال نظر
          </button>
        </div>
      </div> */}
    </LazyLoading>
  );
};

export default SingleNews;
