import { useState , useEffect } from "react"
import { GetCitiesListHttp, GetProvincesListHttp } from "../../../../../../services/provinces"

const LocationsList = ({dataSchema , onDataSchemaHandler}) => {
  
    const [provincesList , setProvincesList] = useState([])
    const [citiesList , setCitiesList] = useState([])

    useEffect(() => {
        onGetProvincesList()
    },[])

    const onGetProvincesList = async () => {
        try {
            const response = await GetProvincesListHttp()

            if(response.status === 200){
                setProvincesList([
                    ...response.data.data
                ])
            }

        } catch (error) {
            console.log('error in get provinces list : ' , error)
        }
    }

    const onSetProvinceHandler =  async (provinceId) => {
        try {
            const response = await GetCitiesListHttp({provinceId})

            if(response.status === 200){
                
                onDataSchemaHandler("city","")

                setCitiesList([
                    ...response.data.data
                ])
            }

        } catch (error) {
            console.log("error in get cities : " , error)
        }
    }

    return (
    <div className="w-full space-y-2">
        <div className="my-2 flex flex-col items-start justify-start w-full">
            <span>استان</span>
            <select onChange={(e) => onSetProvinceHandler(+e.target.value)} className="w-full border-2 border-[#9b9b9b] p-2 rounded-md">
                {provincesList.map((singleProvinces) => <option value={singleProvinces.id} key={singleProvinces.id}>
                    {singleProvinces.name}
                </option>)}
            </select>
        </div>
        <div className="my-2 flex flex-col items-start justify-start w-full">
            <span>شهر</span>
            <select value={dataSchema.city} onChange={(e) => onDataSchemaHandler("city" , +e.target.value)} className="w-full border-2 border-[#9b9b9b] p-2 rounded-md">
                <option value='' disabled selected>یک شهر را انتخاب کنید</option>
                {citiesList.map((singleCity) => 
                    <option value={singleCity.id} key={singleCity.id}>
                        {singleCity.name}
                    </option>
                )}
            </select>
        </div>
    </div>
  )
}

export default LocationsList