import React from "react";
import { useState, useEffect } from "react";
//Components
import LoadingPReportListCard from "../../../components/pages/prformanceReport/loadingPReportListCard";
import PReportListCard from "../../../components/pages/prformanceReport/performanceListCard";
import LazyLoading from "../../../components/common/lazyLoading";
//SVG
import { ReactComponent as LeftArrow } from "./../../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "./../../../assets/svg/home-2.svg";
import { ReactComponent as DotsGroup } from "./../../../assets/svg/Group.svg";
//service
import { GetAllPerformanceReport } from "../../../services/performanceReport";

const PerformanceReport = () => {
  const [PReport, setPReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    asyncGetPReportList();
  }, []);

  const asyncGetPReportList = async () => {
    setIsLoading(true);
    try {
      const response = await GetAllPerformanceReport();

      //check repsonse status
      if (response.status === 200) {
        setPReport([...response.data.data]);
      } else {
        //error occure
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const generaList = () => {
    return (
      Array.isArray(PReport) &&
      PReport.length > 0 &&
      PReport.map((PReportItem, index) => (
        <PReportListCard
          key={PReportItem.id}
          id={PReportItem.id}
          createdAt={PReportItem.created_at}
          image={PReportItem.image}
          title={PReportItem.title}
          description={PReportItem.description}
        />
      ))
    );
  };

  const loadingCard = () => {
    return <LoadingPReportListCard />;
  };

  return (
    <div className="-mt-[7rem]">
      <div className="flex items-center gap-3 mr-7">
        <Home />
        <LeftArrow />
        <span>گزارش عملکرد</span>
      </div>
      <div className="flex items-center gap-3 mr-7 mt-10 mb-16">
        <DotsGroup />
        <span className="text-[#334155] font-bold text-3xl">لیست گزارش</span>
      </div>
      <div className="grid grid-cols-12 gap-y-10">
        <LazyLoading
          card={loadingCard}
          isLoading={isLoading}
          numberRepetation={6}
        >
          {generaList()}
        </LazyLoading>
      </div>
      <div className="mt-10 mx-3">{/* <Pagination /> */}</div>
    </div>
  );
};

export default PerformanceReport;
