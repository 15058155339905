import React from "react";

//SVG
import { ReactComponent as User } from "./../../../../assets/svg/user.svg";
import { ReactComponent as Time } from "./../../../../assets/svg/time.svg";

const LoadingEducationCard = () => {
  return (
    <div className="col-span-12 sm:col-span-6 md:col-span-4  xl:col-span-3 bg-white rounded-xl px-2 py-5 h-fit w-full">
      <div className="w-full h-[175px] bg-gray-300 animate-pulse rounded-md lazyLoading"></div>
      <div className="w-2/3 bg-gray-300 animate-pulse h-7 rounded-md my-5"></div>
      <div className="flex items-center gap-2 my-5 pb-3 border-b border-[#F3F4F8] text-[#AEAEAE]">
        <User />
        <span className="w-1/3 bg-gray-300 animate-pulse h-7 rounded-md"></span>
      </div>
      <div className="flex justify-between">
        <div className="flex w-full items-center gap-2 text-[#31ADE4]">
          <Time />
          <span className="w-1/3 bg-gray-300 animate-pulse h-5 rounded-md"></span>
        </div>
        <div className="w-1/3 bg-gray-300 animate-pulse h-5 rounded-md"></div>
      </div>
    </div>
  );
};

export default LoadingEducationCard;
