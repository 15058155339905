import cooperation from './../../../../assets/image/cooperation.png'

const VoluntaryCooperation = () => {
  return (
    <>
    <div className="md:bg-[#f5f5f5] px-5 py-2 pb-5">
      <div className="flex flex-col w-11/12 sm:w-3/4 max-w-3xl lg:mb-10 mt-10 bg-gradient-to-br from-green-900 from-10% green-800 via-30% to-[#004b23] to-90% mx-auto rounded-3xl p-4 place-content-center place-items-center"
      >
        <img className='w-16' src={cooperation}/>
        <div className="flex flex-col items-center justify-center place-self-center ">
          <h1 className="text-[#dad7cd] text-2xl md:text-3xl lg:text-4xl font-medium mb-6">
            همکاری داوطلبانه
          </h1>
          <p className="text-[#dad7cd] md:text-lg lg:text-xl mb-9 max-w-[15rem] md:max-w-[20rem] lg:max-w-[30rem] !text-center">
            شما نیز درصورت تمایل می توانید با تکمیل فرم زیر به گروه جهادی شهید
            حججی بپیوندید.
          </p>
        </div>
        <div className=" flex justify-center order-1">
          <a
            href="http://app.epoll.pro/e/o/MDI4NjA3"
            className="subHamkari text-[#073358] font-bold bg-[#dad7cd] md:shadow-inner hover:bg-transparent hover:border-2 hover:text-white  hover:scale-110 duration-200 rounded-xl px-5 sm:px-16 sm:py-3 py-2 text-sm  md:text-base lg:px-10 lg:py-3 font-medium lg:text-lg"
          >
            تکمیل فرم
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default VoluntaryCooperation;
