import React, { useState } from "react";

//sevices
import { PostPhoneNumber } from "../../../../../services/auth";

//component
import CharityInput from "../../../../common/input/charityInput";
import LoadingBtn from "../../../../common/loadingBtn";
//helper
import {
  emailValidataion,
  phoneNumberValidation,
} from "../../../../../helper/Validating";

//redux
import { useDispatch } from "react-redux";
import { addPhoneNumber } from "../../../../../slice/user";
import person from "./../../../../../assets/image/person.png"

const SignUpPhone = ({ stepForwardHandler }) => {
  const dispatch = useDispatch();

  const [dataSchema, setDataSchema] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const dataSchemaHadnler = (target, value) => {
    setDataSchema(value);

    //check if have error =>  delete it
    if (error) setError("");
  };

  const checkPhoneNumberHandler = () => {
    let isCorrectInput = true;

    if (dataSchema && dataSchema.trim().length === 0) {
      setError("لطفا شماره موبایل خود را وارد کنید");
      isCorrectInput = false;
    } //check user input is email
    else if (!emailValidataion(dataSchema)) {
      //user input is not email , check if Phone number
      if (phoneNumberValidation(dataSchema)) {
        //user input is phone number
      } else {
        //user input is not phonenumber and email
        setError("لطفا شماره موبایل خود را درست وارد کنید");
        isCorrectInput = false;
      }
    }

    if (isCorrectInput) {
      asyncPostPhoneNumber();
    }
  };

  const asyncPostPhoneNumber = async () => {
    setIsLoading(true);
    try {
      const response = await PostPhoneNumber({ dataSchema });
      //check status code
      if (response.status === 200) {
        //send code successfully
        dispatch(addPhoneNumber(dataSchema));
        stepForwardHandler();
      } else {
        setError("مشکلی رخ داده لطفا دوباره تلاش کنید");
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="-mt-[8rem]">
      
      <div className="relative border-2 border-green-900 w-[90vw] h-[250px] grid items-center sm:w-[400px] md:w-[500px] lg:w-[700px] lg:h-[300px] xl:w-[830px] max-w-[830px] mx-auto p-5 rounded-xl"
 >
        <span className="absolute border-2 border-green-900 top-0 right-[50%] w-[90px] h-[90px] lg:w-[120px] lg:h-[120px] translate-x-1/2 bg-[#f5f5f5] -translate-y-10 rounded-full -z-50"
   >
        </span>
        <span className="absolute top-0 right-[50%] translate-x-1/2 -translate-y-10">
          <img src={person} className="w-[90px] lg:w-[120px] lg:h-[120px] h-[90px] bg-[#f5f5f5] rounded-full"/>
        </span>
        <CharityInput
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              checkPhoneNumberHandler();
            }
          }}
          label={"لطفا شماره موبایل خود را وارد نمایید."}
          placeholder={"شماره موبایل"}
          onChangeHandler={dataSchemaHadnler}
          target="phoneNumber"
          type="text"
          error={error}
        />
        <LoadingBtn
          className={
            "absolute bottom-0 w-8/12 rounded-t-none right-[50%] translate-x-1/2 translate-y-[5.2rem] bg-[#f5f5f5] hover:text-green-200 duration-200 font-medium"
          }
          action={checkPhoneNumberHandler}
          text={"دریافت رمز یکبار مصرف"}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SignUpPhone;
