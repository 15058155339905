import { useState } from "react"

import {ReactComponent as PhoneSvg} from "./../../../../assets/svg/phone.svg"

const ConnectUs = () => {
    const [isOpen , setIsOpen] = useState(false)

    return (
    <div className="fixed flex flex-col items-start justify-start right-10 bottom-10">
        <div className={`${isOpen ? "h-auto p-3": "h-0 p-0"} flex flex-col gap-y-3 bg-[#dad7cd] rounded-md shadow-md mb-2 duration-200 overflow-hidden text-green-900`}>
            <div>
                <span>شماره تلفن : </span>
                <span>09123331174</span>
            </div>
        </div>
        <button onClick={() => setIsOpen((prevState) => !prevState)} className="w-12 h-12 rounded-full bg-[#dad7cd] flex items-center justify-center text-green-900">
            <PhoneSvg className="w-5 h-5" />
        </button>
    </div>
  )
}

export default ConnectUs