import React, { useState } from "react";

import moment from "moment-jalaali";

//component
import LoadingBtn from "../../../../common/loadingBtn";

//service
// import { GetSingleOrder } from "../../../../../services/order";
import { BuyCourse } from "../../../../../services/order";
import { PayOrder } from "../../../../../services/order";

//SVG
import { ReactComponent as User } from "./../../../../../assets/svg/user.svg";
import { ReactComponent as Camera } from "./../../../../../assets/svg/camera.svg";
import { ReactComponent as Condition } from "./../../../../../assets/svg/condition.svg";
import { ReactComponent as Date } from "./../../../../../assets/svg/date.svg";
import { ReactComponent as Level } from "./../../../../../assets/svg/level.svg";
import { ReactComponent as Time } from "./../../../../../assets/svg/timee.svg";

//cookie
import { useCookies } from "react-cookie";

//hooks
import PriceConvertor from "../../../../../hooks/usePrice";
import TimeConverter from "../../../../../hooks/useTime";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const CourseDetail = ({
  className,
  price,
  author,
  status,
  updated_at,
  discount,
  // user_own_it,
  caption,
  courseId,
}) => {
  const [cookies] = useCookies(["token"]);
  moment.loadPersian({ usePersianDigits: true });

  const [isLoading, setIsLoading] = useState(false);

  const asyncBuyCourse = async () => {
    const token = cookies.token;

    setIsLoading(true);

    try {
      //request for buying ticket and get order id from backend
      const response = await BuyCourse({
        token,
        courseId,
      });

      //check response status
      if (response.status === 201) {
        //request for payorder
        const pay_response = await PayOrder({
          token,
          orderId: response.data.data.order_id,
        });

        //check pay_response status
        if (pay_response.status === 200) {
          //pay successfully
          toast.success("در حال انتقال به صفحه ی پرداخت");

          //reroute to where want
          window.location.href = `${pay_response.data.redirect}`;
        } else {
          //an error occurre in pay_response
        } //// pay_response
      } else {
        //an error occurre in response (buy Response)
        //check if error be cuz of unAuthenticating
        if (response.status === 401) {
          toast.error("لطفا وارد شوید");
        }
      } //// response (buy_response)
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className={`bg-[#FFFFFF] rounded-md p-5 ${className}`}>
      <div className="flex flex-row justify-between pb-4 border-b-2">
        <p className="font-bold">قیمت این دوره :</p>
        <PriceConvertor
          discount={discount}
          price={price}
          priceClass={"text-[#6FC341] text-base sm:text-lg"}
          typeClass={"text-[#6FC341]"}
        />
      </div>
      <div className="flex flex-col gap-y-5 py-5">
        <div className="flex gap-y-2 flex-col sm:flex-row text-sm lg:text-base">
          <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
            <User className="text-[#B0B9BE]" />
            <span>مدرس دوره : </span>
          </div>
          <span className="text-[#2D3A6E] font-medium">{author}</span>
        </div>
        {/* <div className="flex gap-y-2 flex-col sm:flex-row text-sm lg:text-base">
          <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
            <Camera />
            <span> تعداد ویدئو : </span>
          </div>
          <span className="text-[#2D3A6E] font-medium">
            {videoCount === undefined ? "تعداد ویدیو ثبت نشده" : videoCount}
          </span>
        </div>
        <div className="flex gap-y-2 flex-col sm:flex-row text-sm lg:text-base">
          <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
            <Time />
            <span> مدت زمان دوره : </span>
          </div>
          <TimeConverter
            className={"text-[#2D3A6E] font-medium"}
            seconds={duration}
          />
        </div> */}
        {/* <div className="flex gap-y-2 flex-col sm:flex-row text-sm lg:text-base">
          <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
            <Level />
            <span> توضیحات دوره : </span>
          </div>
          <p className="text-[#2D3A6E] font-medium">{caption}</p>
        </div> */}
        <div className="flex gap-y-2 flex-col sm:flex-row text-sm lg:text-base">
          <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
            <Condition />
            <span> وضعیت دوره : </span>
          </div>
          <span className="text-[#31ADE4] font-medium">
            {status === undefined ? "وضعیت ویدیو ثبت نشده" : status === 1 ? 'فعال' : 'غیر فعال'}
          </span>
        </div>
        <div className="flex gap-y-2 flex-col sm:flex-row text-sm lg:text-base">
          <div className="flex items-center gap-x-2 ml-2 text-[#B0B9BE]">
            <Date />
            <span> تاریخ اخرین بروزرسانی : </span>
          </div>
          <span className="text-[#2D3A6E] font-medium">
            {moment(updated_at).format("jYYYY/jMM/jDD")}
          </span>
        </div>
        {/* {cookies.token === undefined ? (
          <Link
            to={"/auth"}
            className="text-center bg-[#31ADE4] text-white font-medium border-2 border-[#31ADE4] hover:bg-white hover:text-[#31ADE4] duration-200 p-3 rounded-md w-full"
          >
            ورود به سایت
          </Link>
        ) : user_own_it ? (
          <button className="bg-[#31ADE4] text-white font-medium border-2 border-[#31ADE4] hover:bg-white hover:text-[#31ADE4] duration-200 p-3 rounded-md w-full">
            ورود به دوره
          </button>
        ) : (
          <LoadingBtn
            action={asyncBuyCourse}
            isLoading={isLoading}
            className={
              "!mb-0 bg-[#6FC341] text-white font-medium border-2 border-[#6FC341] hover:bg-white hover:text-[#6FC341] duration-200"
            }
            text={"ثبتـــ نام در این دوره"}
          />
        )} */}
      </div>
    </div>
  );
};

export default CourseDetail;
