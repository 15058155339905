import React, { useState, useEffect } from "react";
//react-router-dom
import { Link } from "react-router-dom";
//cookie
import { useCookies } from "react-cookie";
//SVG
import { ReactComponent as Search } from "./../../../../assets/svg/search.svg";
import { ReactComponent as User } from "./../../../../assets/svg/user.svg";
import { ReactComponent as ShoppingBasket } from "./../../../../assets/svg/shopping_basket.svg";
import { ReactComponent as Hamburger } from "./../../../../assets/svg/hamburger.svg";
import { ReactComponent as CloseCircle } from "./../../../../assets/svg/closeCircle.svg";
import { ReactComponent as ArrowDown } from "./../../../../assets/svg/arrowDown.svg";
//PNG
import Logo from "./../../../../assets/image/logo.png";

const Header = () => {
  const [cookies] = useCookies(["token"]);

  const [isOpenNavbar, setIsOpenNavbar] = useState(false);
  const [scrollPosition, setScrollPosition] = useState();

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    setScrollPosition(winScroll);
  };

  const toggleNavbarStatusHandler = () => {
    setIsOpenNavbar(!isOpenNavbar);
  };

  return (
    <div
      className={`"fixed z-50 top-0 right-0 w-full rounded-none px-5 py-1"
        : "absolute top-8 left-0 right-0 mx-auto sm:w-full rounded-xl px-7 py-3"
        }  bg-white shadow-[0_2px_7px_0px_rgba(6,23,48,0.1)]`}
    >
      <div className="flex justify-between items-center container mx-auto">
        <Link className="block w-12 h-12 md:w-16 md:h-16 mb-3" to={"/"}>
          <img src={Logo} alt="mahak" />
        </Link>
        <div className="hidden xl:flex justify-center gap-x-5 grow">
          <Link
            to={"/actions"}
            className="hover:text-green-900 hover:border-b-4 hover:border-green-900 hover:px-2 duration-200 hover:-translate-y-1"
          >
            آشنایی با گروه جهادی
            {/* <ArrowDown className="w-5" /> */}
          </Link>
          <Link
            to={"/performance_report"}
            className="hover:text-green-900 hover:border-b-4 hover:border-green-900 hover:px-2 duration-200 hover:-translate-y-1"
          >
            {/* <ArrowDown className="w-5" /> */}
            گزارش عملکرد
          </Link>{" "}

          <Link
            to={"/help"}
            className="hover:text-green-900 hover:border-b-4 hover:border-green-900 hover:px-2 duration-200 hover:-translate-y-1"
          >
            روش های کمک به گروه
            {/* <ArrowDown className="w-5" /> */}
          </Link>
          <Link
            to={"/educationCourses"}
            className="hover:text-green-900 hover:border-b-4 hover:border-green-900 hover:px-2 duration-200 hover:-translate-y-1"
          >
            دوره های آموزشی
          </Link>
          <a href="https://zarinp.al/hojaji.org" className="hover:text-green-900 hover:border-b-4 hover:border-green-900 hover:px-2 duration-200 hover:-translate-y-1">
            کمک مالی
          </a>
        </div>
        <div className="flex justify-left items-center gap-x-7 xl:gap-x-10 py-3 text-[#52575C]">
          <button className="hidden xl:block hover:text-[#9e9e9e] duration-200">
            <Search />
          </button>
          <button className="hover:text-green-100  duration-200">
            <ShoppingBasket />
          </button>

          <button
            onClick={toggleNavbarStatusHandler}
            className="block xl:hidden  rounded-lg"
          >
            <Hamburger />
          </button>
          {cookies.token ? (
            <Link
              className="hidden xl:block hover:text-green-100  duration-200"
              to={"/auth"}
            >
              <User />
            </Link>
          ) : (
            <Link
              to={"/auth"}
              className="w-48 text-center font-medium text-sm hidden xl:block bg-[#344e41] border-2 border-[#3a5a40] hover:bg-white duration-200 text-[#dad7cd] py-3 px-4 rounded hover:text-green-900"
              style={{boxShadow:'0px 0px 5px #023e8a'}}
            >
              ورود / ثبت نام
            </Link>
          )}
        </div>

        {/* mobile view Navbar */}
        <div
          onClick={toggleNavbarStatusHandler}
          className={`${isOpenNavbar ? "left-0" : "left-full"
            } duration-200 z-[90] fixed block xl:hidden bg-black bg-opacity-70 backdrop-blur-sm top-0 right-0 bottom-0 left-0 overflow-hidden`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-9/12 sm:w-7/12 md:w-5/12 h-full bg-[#f5f5f5]"
          >
            <div className=" w-full flex flex-col items-center gap-y-5 p-5 sm:p-10 text-[#52575C]">
              <button onClick={toggleNavbarStatusHandler} className="self-end hover:text-[#fca311] duration-200">
                <CloseCircle />
              </button>
              <div className="self-start flex flex-col items-start gap-y-10 my-10">
                <Link
                  to={"/actions"}
                  className="hover:text-green-800  hover:border-r-4 hover:border-green-800  hover:px-2 duration-200"
                >
                  آشنایی با گروه جهادی
                  {/* <ArrowDown className="w-5" /> */}
                </Link>
                <Link
                  to={"/performance_report"}
                  className="hover:text-green-800  hover:border-r-4 hover:border-green-800  hover:px-2 duration-200"
                >
                  گزارش عملکرد
                  {/* <ArrowDown className="w-5" /> */}
                </Link>{" "}
                <Link
                  to={"/help"}
                  className="hover:text-green-800  hover:border-r-4 hover:border-green-800  hover:px-2 duration-200"
                >
                  روش های کمک به گروه
                  {/* <ArrowDown className="w-5" /> */}
                </Link>
                <Link
                  to={"/educationCourses"}
                  className="hover:text-green-800  hover:border-r-4 hover:border-green-800  hover:px-2 duration-200"
                >
                  دوره های آموزشی
                </Link>
                <a href="https://zarinp.al/hojaji.org"
                  className="hover:text-green-800  hover:border-r-4 hover:border-green-800  hover:px-2 duration-200">
                    کمک مالی
                </a>
              </div>
              {cookies.token ? (
                <Link
                  className="w-48 text-center font-medium text-sm bg-green-900 border-2 border-[#3a5a40] hover:bg-white hover:text-[#3F72AF] duration-200 text-white py-3 px-4 rounded"
                  to={"/auth"}
                >
                  ورود به حساب کاربری
                </Link>
              ) : (
                <Link
                  to={"/auth"}
                  className="w-48 text-center font-medium text-sm text-green-900 py-3 px-4 hover:bg-green-900 hover:text-white duration-200 font-bold"
                  style={{
                    borderRadius: '10px',
                    background: '#efeee',
                    boxShadow: '4px 4px 6px #ccc,-4px -4px 6px #fff',
                  fontSize:'1rem'}}
                >
                  ورود / ثبت نام
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
