//toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//react-router-dom
import { Outlet } from "react-router-dom";
//component
import Footer from "./footer";
import Header from "./header";
import ConnectUs from "./connectUs";
const Layout = () => {
  return (
    <div className="mx-auto flex flex-col">
      <div className="relative mb-40">
        <Header />
      </div>
      <Outlet />
      <ConnectUs />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Layout;
