//react-router-dom
import { useNavigate } from "react-router-dom";

//svg
import { ReactComponent as Home } from "./assets/svg/home-2.svg";
import { ReactComponent as GoBack } from "./assets/svg/arrowBack.svg";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center gap-7 bg-white p-7 rounded-xl">
      <span className="font-semibold text-2xl">
        متاسفانه صفحه ی مورد نظر پیدا نشد !
      </span>
      <span className="font-medium text-lg text-[#00ADB5]">
        ما در حال توسعه ی سایت هستیم به زودی صفحه ی مورد نظر در دسترس قرار
        میگیرد
      </span>
      <div className="py-10 flex items-center justify-around w-full text-gray-600 font-medium text-lg">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="hover:text-[#FF6D00] duration-200 flex items-center justify-center gap-2"
        >
          بازگشت به صفحه ی قبلی
          <GoBack className="w-6" />
        </button>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="hover:text-[#FF6D00] duration-200 flex items-center justify-center gap-2"
        >
          رفتن به خانه
          <Home />
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
