import React from "react";
//Components
import DownFooter from "./DownFooter";
import UpFooter from "./UpFooter";

const Footer = () => {
  return (
    <div className="flex flex-col bg-[#f5f5f5] sm:mx-0 lg:m-0">
      <UpFooter />
      <DownFooter />
    </div>
  );
};

export default Footer;
