const UsefullSection = () => {
  return (
    <div className="bg-[#ffffff] flex flex-col rounded-md p-5 ">
      <span className=" w-full pb-3 border-b-2"> لینک های مفید</span>
      <ul className="mt-4 list-disc list-inside marker:text-green">
        <li className=""> آموزش سئو </li>
        <li className="my-4"> آموزش برنامه نویسی به کودکان و نوجوانان</li>
        <li className=""> آموزش طراحی سایت </li>
      </ul>
    </div>
  );
};

export default UsefullSection;
