import AuthPic from "./../../../../assets/image/auth_pic.jpg";
import Photography from "./../../../../assets/image/Photography.png";
import Media from "./../../../../assets/image/Media.png";
import education from "./../../../../assets/image/education.png";
import JobCreation from "./../../../../assets/image/JobCreation.png";
import question from "./../../../../assets/image/question.png";
import backCirclee from "./../../../../assets/image/backCirclee.png";

const list = [
  {
    title: "عکاسی و فیلمبرداری ",
    img:Photography,
  },
  {
    title: "رسانه",
    img:Media,
  },
  {
    title: "آموزش",
    img:education,
  },
  {
    title: "اشتغال زایی",
    img:JobCreation,
  },
];
const Details = () => {
  return (
    <div className="rounded-lg mt-16 text-green-900 md:mx-auto max-w-[830px]">
      <img src={backCirclee}
      className="w-56 lg:w-72 hidden md:block absolute right-[-5%] top-[5%] lg:-top-20 lg:-right-40 opacity-40 -z-50"
      />
      <img src={backCirclee}
      className="w-64 lg:w-80 hidden md:block absolute left-0 top-[26%] opacity-40 -z-50"
      />
      <img src={backCirclee}
      className="w-72 lg:w-96 hidden md:block absolute right-10 top-[62%] lg:top-[55%] lg:right-20 opacity-40 -z-50"
      />
        <span className="font-bold block text-right pb-2.5 p-3">نیکوکار گرامی</span>
        <p className="text-right text-sm lg:text-md max-w-[95vw] mb-4 p-3">
          گروه جهادی شهید حججی ازشهرهای مختلف ایران عضو می‌پذیرد. در هر شهر یا
          روستایی که ساکن هستید ، اگر در زمینه های اشتغال‌زایی، بهداشت ودرمان و
          کارهای رسانه‌ای مهارت دارید و یا دغدغه مشارکت در محرومیت زدایی از مناطق
          کم‌برخوردار کشور عزیزمان، ایران و به طور خاص منطقه بشاگرد در استان
          هرمزگان را دارید، می‌توانید با تکمیل فرم عضویت به خانواده جهادی شهید
          حججی بپیوندید.
        </p>
        {/* <div style={{height: '70px', overflow: 'hidden'}} ><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}><path d="M-1.35,-1.47 C486.23,183.55 16.70,191.43 499.77,1.48 L542.66,160.91 L-19.97,153.04 Z" style={{stroke: 'none', fill: '#f5f5f5'}}></path></svg></div> */}
      <div className="md:grid md:place-content-center md:place-items-center md:grid-cols-2 md:gap-y-24 md:mx-auto lg:mx-auto">
        {list.map((item, ind) => (
            <div
              key={ind}
              className="cardSingUp w-[270px] h-[270px] flex flex-col md:flex-col-reverse md:gap-y-4 place-content-center place-items-center rounded-[3rem] bg-[#f8f9fa] shadow-lg p-2 mt-12 mr-4"
              style={{position:"relative"}}
            >
            {  <div>
                <img src={item.img} className="w-16 grid place-content-center place-items-center"/>
              </div>}
              <div className="flex gap-2 place-content-center place-items-center">
                <span className="text-4xl font-bold text-green-900">موضوع</span>
                <span className="text-4xl text-green-900 font-bold">0{ind+1}</span>
              </div>
              <h1 className="flex px-4 pt-3 mt-2 font-bold place-content-center place-items-center gap-3 place-self-center text-green-900 text-xl font-medium text-center">
                  {item.title}
              </h1>
              <div
              className={`circleSing-${ind} absolute w-[60px] h-[100px] top-[50%] right-[0] -translate-y-1/2 rounded-l-full `}
              style={{boxShadow:'-5px 5px 15px gray'}}>
              </div>
              <div
              className={`circleline-${ind} absolute -z-50 w-[300px] sm:w-[80vw] h-[100px] top-[50%] right-[0] -translate-y-1/2 rounded-r-full shadow-2xl`} >
        
              </div>
            </div>
          ))}
      </div>
      <div className="flex w-11/12  md:relative md:h-[150px] max-w-[600px] rounded-xl mx-auto place-content-center place-items-center gap-4 mt-24 px-4 lg:mt-40"
      style={{boxShadow:'inset 4px 4px 6px #ccc,inset -4px -4px 6px #fff'}}>
        <img src={question} className="w-32 md:w-56 md:absolute bottom-0 right-10"></img>
        <div className="flex flex-col text-green-900 md:mr-16">
          <h2>@jahadihojaji</h2>
          <h2>09123331174</h2>
          <h2>سید محمدمهدی حسینی</h2>
        </div>
      </div>
    </div>
  );
};

export default Details;
