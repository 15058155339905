import { ReactComponent as ArrowRight } from "./../../../../assets/svg/arrowRight.svg";

export function NewsSwiperBtnPrev({ swiperClass }) {
  return (
    <button
      className={`${swiperClass} border-2 border-green-200 bottom-10 right-1/2 z-10 mx-5 flex items-center justify-center h-8 sm:h-10 w-8 sm:w-10 rounded-xl active:scale-90 duration-200 hover:bg-opacity-60 bg-opacity-50 bg-[#f5f5f5]`}

    >
      <ArrowRight className="w-2 sm:w-3 rotate-180 text-green-200" />
    </button>
  );
}

export function NewsSwiperBtnNext({ swiperClass }) {
  return (
    <button
      className={` ${swiperClass} border-2 border-green-200 bottom-10 left-1/2 z-10 mx-5 flex items-center justify-center h-8 sm:h-10 w-8 sm:w-10 rounded-xl active:scale-90 duration-200 hover:bg-opacity-60 bg-opacity-50 bg-[#f5f5f5]`}
    >
      <ArrowRight className="w-2 sm:w-3 text-green-200" />
    </button>
  );
}
