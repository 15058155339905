import moment from "moment-jalaali";

import { Link } from "react-router-dom";

const SingleNews = ({ index, activeIndex, data }) => {
  moment.loadPersian({ usePersianDigits: true });
  const { updated_at, image, id } = data;
  return (
    <div
      className={`${
        index === activeIndex ? "scale-1" : "scale-[0.65]"
      } flex flex-col items-center justify-center duration-200`}
    >
      <div className="relative w-3/4 h-[175px] sm:w-[500px] sm:h-[250px] mb-5">
        {index === activeIndex && (
          <span className="bg-white text-[10px] font-medium p-1 rounded-md absolute top-2 right-2">
            {moment(updated_at).format("jD jMMM jYYYY")}
          </span>
        )}
        <Link to={`/news/${id}`}>
          <img
            className="object-fill w-full h-full  rounded-md"
            src={`${image}`}
            alt="old woman"
          />
        </Link>
      </div>
    </div>
  );
};

export default SingleNews;
