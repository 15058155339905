import { bindActionCreators, createSlice } from "@reduxjs/toolkit";

const initialState = {
  phoneNumber: "",
  firstName: "",
  lastName: "",
  birthDay: "",
};

const userSlice = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    addPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
  },
});

export const { addPhoneNumber } = userSlice.actions;

export default userSlice.reducer;
