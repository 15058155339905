import useFetch from "./../hooks/useFetch";
const endPoint = "news";

export async function GetAllNews() {
  const apiCall = await useFetch().get(endPoint);
  return apiCall;
}

export async function GetSingleNews({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`);
  return apiCall;
}
