//SVG
import { ReactComponent as User } from "./../../../../../assets/svg/user.svg";
import { ReactComponent as Camera } from "./../../../../../assets/svg/camera.svg";
import { ReactComponent as Condition } from "./../../../../../assets/svg/condition.svg";
import { ReactComponent as Date } from "./../../../../../assets/svg/date.svg";
import { ReactComponent as Level } from "./../../../../../assets/svg/level.svg";
import { ReactComponent as Time } from "./../../../../../assets/svg/timee.svg";
import { ReactComponent as Telegram } from "./../../../../../assets/svg/telegram.svg";
import { ReactComponent as Twitter } from "./../../../../../assets/svg/twitter.svg";
import { ReactComponent as Facebook } from "./../../../../../assets/svg/facebook.svg";

const LoadingSingleCourse = () => {
  return (
    <div className="grid grid-cols-12 m-4 gap-3 mx-5 sm:mx-auto sm:w-full">
      <div className="col-span-12 h-fit md:col-span-5 lg:col-span-4 flex flex-col">
        <div className="bg-[#FFFFFF] rounded-md p-5 ">
          <div className="flex flex-row justify-between pb-4 border-b-2">
            <p className="font-bold">قیمت این دوره :</p>
            <i className="w-1/3 bg-gray-300 animate-pulse rounded-md" />
          </div>
          <div className="flex flex-col gap-y-5 py-5">
            <div className="flex ">
              <div className="flex items-center gap-x-2 ml-2">
                <User className="text-[#B0B9BE]" />
                <span>مدرس دوره : </span>
              </div>
              <span className="w-1/3 bg-gray-300 animate-pulse rounded-md"></span>
            </div>
            <div className="flex ">
              <div className="flex items-center gap-x-2 ml-2">
                <Camera />
                <span> تعداد ویدئو : </span>
              </div>
              <i className="w-1/3 bg-gray-300 animate-pulse rounded-md" />
            </div>
            <div className="flex ">
              <div className="flex items-center gap-x-2 ml-2">
                <Time />
                <span> مدت زمان دوره : </span>
              </div>
              <span className="w-1/3 bg-gray-300 animate-pulse rounded-md"></span>
            </div>
            <div className="flex ">
              <div className="flex items-center gap-x-2 ml-2">
                <Level />
                <span> سطح دوره : </span>
              </div>
              <span className="w-1/3 bg-gray-300 animate-pulse rounded-md"></span>
            </div>
            <div className="flex ">
              <div className="flex items-center gap-x-2 ml-2">
                <Condition />
                <span> وضعیت دوره : </span>
              </div>
              <span className="w-1/3 bg-gray-300 animate-pulse rounded-md"></span>
            </div>
            <div className="flex ">
              <div className="flex items-center gap-x-2 ml-2">
                <Date />
                <span> تاریخ اخرین بروزرسانی : </span>
              </div>
              <span className="w-1/3 bg-gray-300 animate-pulse rounded-md"></span>
            </div>
            <button
              disabled
              className="bg-gray-400 cursor-default flex text-[#fff] justify-center p-3 rounded-md w-full "
            >
              ثبتـــ نام در این دوره
            </button>
          </div>
        </div>
        <div className="my-7 bg-[#ffffff]  flex justify-between items-center p-5 rounded-md ">
          <span className="text-[#2D3A6E] font-bold"> به اشتراک گذاری </span>
          <div className="flex items-center gap-2 text-[#2D3A6E]">
            <button className="w-9 h-9 flex items-center p-1 rounded-full border-2 hover:border-[#2D3A6E] duration-200">
              <Twitter className="w-10" />
            </button>
            <button className="w-9 h-9 flex items-center p-1 rounded-full border-2 hover:border-[#2D3A6E] duration-200">
              <Facebook className="w-10" />
            </button>
            <button className="w-9 h-9 flex items-center p-1 rounded-full border-2 hover:border-[#2D3A6E] duration-200">
              <Telegram className="w-9" />
            </button>
          </div>
        </div>
        <div className="bg-[#ffffff] rounded-md p-5 mb-5 md:mb-0 ">
          <span className="font-bold text-[#2D3A6E]"> برچسب ها : </span>
          <div className="grid grid-cols-3 gap-5 my-3 w-full">
            <span className="bg-gray-300 animate-pulse p-1 rounded-md w-full h-[25px]"></span>
            <span className="bg-gray-300 animate-pulse p-1 rounded-md w-full h-[25px]"></span>
            <span className="bg-gray-300 animate-pulse p-1 rounded-md w-full h-[25px]"></span>
            <span className="bg-gray-300 animate-pulse p-1 rounded-md w-full h-[25px]"></span>
            <span className="bg-gray-300 animate-pulse p-1 rounded-md w-full h-[25px]"></span>
          </div>
        </div>
      </div>
      <div className="col-span-12 h-fit md:col-span-7 lg:col-span-8 flex flex-col bg-[#ffffff] p-6 rounded-md">
        <span className="w-full bg-gray-300 animate-pulse h-[450px] rounded-lg mb-10"></span>
        <span className="w-full bg-gray-300 animate-pulse h-[75px] rounded-t-lg rounded-l-lg"></span>
        <span className="w-1/3 bg-gray-300 animate-pulse h-[30px] rounded-b-lg mb-10"></span>
        <span className="w-full bg-gray-300 animate-pulse h-[120px] rounded-t-lg rounded-l-lg"></span>
        <span className="w-5/6 bg-gray-300 animate-pulse h-[30px] rounded-b-lg"></span>
      </div>
    </div>
  );
};

export default LoadingSingleCourse;
