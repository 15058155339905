//react-router-dom
import { Routes, Route } from "react-router-dom";

//component
import Layout from "./components/common/layout";
import Home from "./pages/home";
import Auth from "./pages/auth";

import CommitteeList from "./pages/committee";
import SingleCommitteee from "./pages/committee/singleCommittee";

import Education from "./pages/educationCourses/singleEducationCourse";
import EducationCourses from "./pages/educationCourses";
import Invoice from "./pages/invoice";
import News from "./pages/news/newsList";
import SingleNews from "./pages/news/singleNews";
import Actions from "./pages/actions/index.js";
import Help from "./pages/help/index.js";
import PerformanceReport from "./pages/performance_report/performanceReportList";
import SinglePerformanceReport from "./pages/performance_report/singlePerformanceReport";
//404.js
import NotFoundPage from "./404";
import EmployeesList from "./pages/employees";
import SingleEmployee from "./pages/employees/singleemployee";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/auth" element={<Auth />} />
        <Route path="/educationCourses" element={<EducationCourses />} />
        <Route path="/educationCourses/:id" element={<Education />} />
        <Route path="/committee" element={<CommitteeList />} />
        <Route path="/committee/:id" element={<SingleCommitteee />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/news/:id" element={<SingleNews />} />
        <Route path="/news" element={<News />} />
        <Route path="/employees" element={<EmployeesList />}/>
        <Route path="/employees/:id" element={<SingleEmployee/>}/>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/actions" element={<Actions />} />
        <Route path="/help" element={<Help />} />
        <Route path="/performance_report" element={<PerformanceReport />} />
        <Route
          path="/performance_report/:id"
          element={<SinglePerformanceReport />}
        />
      </Route>
    </Routes>
  );
}

export default App;
