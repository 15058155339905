import useFetch from "./../hooks/useFetch";
const endPoint = "title/";

export async function GetAllImages() {
  const apiCall = await useFetch().get(endPoint);
  return apiCall;
}

export async function GetTitleText() {
  const apiCall = await useFetch().get(`title/text`);
  return apiCall;
}
