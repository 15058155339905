import useFetch from "./../hooks/useFetch";
const endPoint = "courses";

export async function GetEducationCoursesList({ filter }) {
  const apiCall = await useFetch().get(`${endPoint}?${filter}`);

  return apiCall;
}

export async function GetSingleCourse({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`);

  return apiCall;
}
