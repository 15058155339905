import React, { useState, useEffect } from "react";

//react router dom
import { useParams, Link } from "react-router-dom";

//service
import { GetSingleCommittee } from "../../../services/committee";

//SVG
import { ReactComponent as LeftArrow } from "./../../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "./../../../assets/svg/home-2.svg";

const SingleCommitteee = () => {
  const { id } = useParams();

  //data
  const [committee, setCommittee] = useState({});

  useEffect(() => {
    httpGetCommitteeList();
  }, []);

  const httpGetCommitteeList = async () => {
    try {
      const response = await GetSingleCommittee({ id });

      if (response.status === 200) {
        setCommittee({
          ...response.data.data,
        });
      }
    } catch (error) {
      console.log("error in get committee list : ", error);
    }
  };

  return (
    <div className="-mt-[9rem] flex flex-col items-center justify-start gap-5 mx-4 text-green-900">
      <div className="flex items-center justify-start gap-2 sm:gap-3 w-full">
        <Link to={"/"}>
          <Home />
        </Link>
        <LeftArrow />
        <Link to={"/committee"}>
          <span className="text-sm sm:text-lg">کمیته</span>
        </Link>
        <LeftArrow />
        <span className="text-[#B0B9BE] text-[14px] sm:text-lg ">
          {committee.name}{" "}
        </span>
      </div>
      <img
        className=" h-[100px] rounded-md object-fill"
        src={committee.image}
        alt="banner"
      />
      <div className="flex flex-col gap-2 w-full text-green-900">
        <div className="flex flex-col items-start gap-1">
          {/* <span className="text-xl font-medium">000 کمیته</span> */}
          <span className="text-lg px-8 py-2 rounded-xl place-self-center mb-12"
          style={{boxShadow:'inset 4px 4px 6px #ccc,inset -4px -4px 6px #fff'}}>
            {committee.name}</span>
        </div>
        <div className="flex flex-col items-start gap-1 py-5 px-10 rounded-xl"
        style={{borderTopRightRadius:'0rem',position:'relative',boxShadow:'inset 4px 4px 6px #ccc,inset -4px -4px 6px #fff'}}>
          <span className="text-xl font-medium -top-11 right-0 p-2 rounded-t-xl bg-[#f5f5f5]"
          style={{position:'absolute',boxShadow:'inset 4px 4px 6px #ccc,inset -4px -4px 6px #fff'}}>درباره کمیته</span>
          <p
            className="text-base sm:text-lg"
            dangerouslySetInnerHTML={{ __html: committee.caption }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default SingleCommitteee;
