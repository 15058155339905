import { useState, useEffect } from "react";

const Timer = (initialTime) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [time]);

  return {
    seconds:
      time % 60 >= 10 ? Math.floor(time % 60) : `0${Math.floor(time % 60)}`,
    minutes:
      time / 60 >= 10 ? Math.floor(time / 60) : `0${Math.floor(time / 60)}`,
  };
};

export default Timer;
