import useFetch from "./../hooks/useFetch";
const endPoint = "activities";

export async function GetAllPerformanceReport() {
  const apiCall = await useFetch().get(endPoint);
  return apiCall;
}

export async function GetSinglePerformanceReport({ id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`);
  return apiCall;
}
