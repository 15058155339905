export default function PriceConvertor({
  price,
  priceClass,
  typeClass,
  containerClass,
  discount,
}) {
  const fixPrice = (price) => {
    //check if price is over than Million
    if (price.toString().length >= 7) {
      let number = price / 1000000;
      if (
        number.toString().split(".")[1] &&
        number.toString().split(".")[1].charAt(0) === "0"
      ) {
        return number.toString().split(".")[0];
      }

      return number.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
    }

    return Number(price).toLocaleString("fa");
  };

  //calculate price with discount
  const computedPercent = ({ percent, amount }) => {
    return amount - (percent / 100) * amount;
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <div className={`relative flex ${containerClass} items-center`}>
          {/* {discount && (
            <i
              className={`w-[130%] h-px absolute bottom-1/5 transform translate-y-1/5 -right-3 bg-red-100 -rotate-[25deg]`}
            ></i>
          )} */}
          <span className={` font-medium sm:ml-2 ml-2 ${priceClass}`}>
            {discount
              ? fixPrice(computedPercent({ percent: discount, amount: price }))
              : fixPrice(price)}
          </span>
          <span
            className={`leading-[15px] text-center break-all flexfont-medium ${typeClass}`}
          >
            {price.toString().length >= 7 ? "میلیون" : "تــومان"}
          </span>
        </div>
        {/* <span
            className={`${
              discount ? "text-red-100 !text-lg" : "text-[#6FC341]"
            } font-medium sm:ml-2 ml-2 ${priceClass}`}
          >
            {fixPrice(price)}
          </span>
          <span
            className={`leading-[15px] text-center break-all flexfont-medium ${typeClass} ${
              discount ? "text-red-100" : "text-cello-50"
            }`}
          >
            {price.toString().length >= 7 ? "میلیون" : "تــومان"}
          </span>
        </div> */}
        {/* {discount && (
          <div className="flex items-center">
            <span
              className={`text-[#6FC341] font-semibold sm:ml-2 ml-2 mr-2 ${priceClass}`}
            >
              {fixPrice(computedPercent({ percent: discount, amount: price }))}
            </span>
            <span
              className={`leading-[15px] text-center text-cello-50 break-all flex font-semibold ${typeClass}`}
            >
              {price.toString().length >= 7 ? "میلیون" : "تــومان"}
            </span>
          </div>
        )} */}
      </div>
    </>
  );
}
