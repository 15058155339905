import React, { useEffect, useState } from "react";
//services
import { GetAllCommittee } from "../../../../services/committee";
//component
import CommitteesCard from "./committeesCard";
//SVG
import { ReactComponent as LeftArrow } from "../../../../assets/svg/leftarrow-news.svg";
import LoadingLayout from "../../../common/loadingLayout";
import { Link } from "react-router-dom";
const Committees = () => {
  const [committee, setCommittee] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    asyncGetAllCommitte();
  }, []);

  const asyncGetAllCommitte = async () => {
    setIsLoading(true);
    try {
      const response = await GetAllCommittee();
      if (response.status === 200) {
        const sliceCommittee = response.data.data.slice(0, 6);
        setCommittee(sliceCommittee);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  // console.log(committee);


  //check list length
  if (Array.isArray(committee) && committee.length === 0) {
    //list is empty , return null instaead of data
    return null;
  }

  return (
    <div className="flex flex-col mt-4 ">
      <div className="place-self-center text-green-900 font-semibold text-4xl p-2">
        <p>کمیته ها</p>
      </div>
      <div className="flex place-content-center place-items-center w-full text-xl text-green-100 md:text-lg lg:text-xl">
        <p>کمیته ها و وظیفه هر کمیته</p>
        
      </div>
      <LoadingLayout isLoading={isLoading}>
        <div className="grid grid-cols-1 items-center gap-5 mt-10 sm:grid-cols-2 md:grid-cols-3  justify-center lg:w-2/3 mx-auto">
          {Array.isArray(committee) &&
            committee.map((card, index) => (
              <CommitteesCard
                key={index}
                id={card.id}
                image={card.image}
                title={card.name}
                description={card.short}
              />
            ))}
        </div>
      </LoadingLayout>
      <Link to={'/committee'}>
        <div className="text-sm md:text-base flex items-center gap-x-2 mx-auto flex place-content-center place-item-center mt-7 p-2">
          <p className="text-2xl text-green-100">مشاهده همه</p>
          <div className="animate-ping">
            <LeftArrow />
          </div>
        </div>
        </Link>
    </div>
  );
};

export default Committees;
