const LazyLoading = ({ card: Card, numberRepetation, isLoading, children }) => {
  return (
    <>
      {isLoading
        ? Array.from(Array(numberRepetation), (item, index) => {
            return <Card key={index} />;
          })
        : children}
    </>
  );
};

export default LazyLoading;
