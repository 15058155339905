import React, { useEffect, useState } from "react";

//services
import { GetAllAuthorities } from "../../../../services/authorities";
//Components
import AuthoritiesCard from "./authoritiesCard";
import LoadingLayout from "../../../common/loadingLayout";
//SVG
import { ReactComponent as LeftArrow } from "./../../../../assets/svg/arrowLeft.svg";
import { Link } from "react-router-dom";

const Authorities = () => {
  const [authorities, setAuthorities] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    asyncGetAllAuthorities();
  }, []);

  const asyncGetAllAuthorities = async () => {
    setIsLoading(true);
    try {
      const response = await GetAllAuthorities();

      if (response.status === 200) {
        const slicedAuthorities = response.data.data.slice(0, 6);

        setAuthorities(slicedAuthorities);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  //check list length
  if (Array.isArray(authorities) && authorities.length === 0) {
    //list is empty , return null instaead of data
    return null;
  }

  console.log("this is authe=orities : ", authorities);

  return (
    <div className="flex flex-col my-32">
      <div className="font-bold text-2xl self-center my-5 lg:text-3xl">
        <p>مسئولین</p>
      </div>
      <div className="flex items-center justify-between w-full text-[#52575C] text-sm md:text-lg lg:text-xl">
        <p>مسولین گروه جهادی را بشناسیم</p>
        <Link to={'/employees'}>
          <p className="text-sm md:text-base flex items-center gap-x-2">
            مشاهده همه
            <LeftArrow />
          </p>
        </Link>
      </div>
      <LoadingLayout isLoading={isLoading}>
        <div className="grid grid-cols-1 justify-center gap-10 mt-14 md:grid-cols-2 lg:grid-cols-6">
          {Array.isArray(authorities) &&
            authorities.map((auth, index) => (
              <AuthoritiesCard
                key={index}
                id={auth.id}
                employee_category={auth.employee_category}
                first_name={auth.first_name}
                image={auth.image}
                last_name={auth.last_name}
              />
            ))}
        </div>
      </LoadingLayout>
    </div>
  );
};

export default Authorities;
