import React, { useState } from "react";

import { useCustomSearchParams } from "../../../../../../hooks/useCustomSearchParams";

//SVG
import { ReactComponent as Search } from "./../../../../../../assets/svg/search.svg";
import { ReactComponent as UpDown } from "./../../../../../../assets/svg/updown.svg";

const orderingList = [
  {
    title: "پیش فرض",
    value: "",
  },
  {
    title: "ارزان ترین",
    value: "cheapest",
  },
  {
    title: "گران ترین",
    value: "expensive",
  },
  {
    title: "جدید ترین",
    value: "newest",
  },
];

const HeaderFilter = () => {
  const [searchParams, setSearchParams] = useCustomSearchParams();

  const [option, setOption] = useState(0);
  const [searchTitle, setSearchTitle] = useState("");

  const onChangeOptionHandler = (target) => {
    //set index of order list as active object
    setOption(target);

    //set search to URL
    setSearchParams({
      ...searchParams,
      sort: orderingList[target].value,
    });
  };

  const onSetSearchTitleHandler = () => {
    setSearchParams({
      ...searchParams,
      title: searchTitle,
    });
  };

  return (
    <div className="grid  justify-center items-center gap-2">
      <div className="bg-[#f5f5f5] rounded-xl w-[90vw] mx-auto p-2">
        <div className="flex justify-center items-center relative">
          <input
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            className="placeholder:italic border-2 border-green-900 placeholder:text-[#aaa] h-[50px] py-3 px-3 w-full lg:mx-auto outline-none rounded-md bg-[#f5f5f5]"
            type="text"
            placeholder="عنوان مورد نظر..."
          />
          <button
            onClick={onSetSearchTitleHandler}
            className="absolute h-[50px] rounded-l-md px-4 left-0 top-0 text-green-900"
          >
            <Search />
          </button>
        </div>
      </div>
      {/* <div className="bg-white col-span-12 md:col-span-6 py-4 lg:p-3 rounded-xl text-xs lg:text-base relative group">
        <div className="flex w-full justify-between items-center px-2 sm:px-5 md:px-1">
          <span className="ml-2">مرتب سازی:</span>
          <span className="md:hidden flex items-center justify-between gap-x-2 border border-[#B0B9BE] px-3 py-1 rounded-md text-[#B0B9BE] cursor-pointer w-40 truncate">
            {orderingList[option].title}
            <UpDown />
          </span>
          <div className="md:relative absolute z-20 left-2 sm:left-5 md:left-0 top-12 md:top-0 bg-white flex-1 group-hover:flex hidden md:flex flex-col md:flex-row justify-around md:w-full w-40 p-2 md:p-0 md:shadow-none shadow-lg rounded-md">
            <button
              onClick={() => onChangeOptionHandler(0)}
              className={`${
                option === 0 && "bg-[#FF6D00] !text-white"
              } text-[#737373] px-2 py-2 lg:p-2 rounded-md`}
            >
              پیش فرض
            </button>
            <button
              onClick={() => onChangeOptionHandler(1)}
              className={`${
                option === 1 && "bg-[#FF6D00] !text-white"
              } text-[#737373] px-2 py-2 lg:p-2 rounded-md`}
            >
              ارزان ترین
            </button>
            <button
              onClick={() => onChangeOptionHandler(2)}
              className={`${
                option === 2 && "bg-[#FF6D00] !text-white"
              } text-[#737373] px-2 py-2 lg:p-2 rounded-md`}
            >
              گران ترین
            </button>
            <button
              onClick={() => onChangeOptionHandler(3)}
              className={`${
                option === 3 && "bg-[#FF6D00] !text-white"
              } text-[#737373] px-2 py-2 lg:p-2 rounded-md`}
            >
              جدیدترین
            </button>
          </div>
        </div>
      </div> */}
      {/* <div className="bg-white col-span-12 md:col-span-6 mt-3 md:mt-0 py-3 md:py-5 rounded-xl md:hidden">
        <div className="flex justify-around items-center">
          <span className="text-sm font-bold">مرتب سازی:</span>
          <select className="text-sm">
            <option value="0">مرتب سازی براساس جدیدترین</option>
            <option value="1">مرتب سازی براساس ارزان ترین</option>
            <option value="2">مرتب سازی براساس گران ترین</option>
          </select>
        </div>
      </div> */}
    </div>
  );
};

export default HeaderFilter;
