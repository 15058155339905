import React, { useEffect } from "react";

import SignUp from "../../components/pages/auth/signUp";
import Details from "../../components/pages/auth/details";

const Auth = () => {
  useEffect(() => {
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="grid grid-cols-1 my-10">
      <SignUp />
      <Details />
    </div>
  );
};

export default Auth;
