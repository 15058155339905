import React from "react";
import { Link } from "react-router-dom";
import borderDash from "./../../assets/image/borderDa.png";

const Help = () => {
  return (
    <>
    <div className=" -mt-[7rem] md:flex md:justify-end md:justify-items-end lg:ustify-center lg:justify-items-end md:gap-5 lg:ml-10 lg:w-[95vw] xl:w-[80vw]">
      <div className="md:relative md:order-2 md:justify-self-end md:mt-[7rem]">
          <h1 className="text-center font-bold text-l mb-1">
             کمک به گروه جهادی شهید حججی
          </h1>
          <p className="text-xs px-4 text-center md:pl-2">
            گروه جهادی شهید حججی علاوه بر
            <a
              className="font-bold m-1 underline text-green-100"
              href="http://app.epoll.pro/e/o/MDI4NjA3"
            >
              عضویت در گروه
            </a>
            ، امکان
            <span>
              {" "}
              <br></br>{" "}
            </span>
             مشارکت مالی شما را به اشکال زیر فراهم کرده است :
            <span>
              {" "}
              <br></br>{" "}
            </span>
            سفارش قربانی و عقیقه، صدقات ، وجوهات شرعی ، تهیه
            <span>
              {" "}
              <br></br>{" "}
            </span>
            نان، رد مظلمه ، کمک به اجرای طرحهای اشتغال‌زایی ،
            <span>
              {" "}
              <br></br>{" "}
            </span>
             آموزشی-فرهنگی و
            بهداشتی توسط گروه جهادی
            <span>
              {" "}
              <br></br>{" "}
            </span>
            ومشارکت در توسعه مزارع
            <span>
              {" "}
              <br></br>{" "}
            </span>
            مورینگا ( فدک‌الزهرا)
          </p>
          <img src={borderDash}
            className="md:absolute mx-auto md:top-50 md:right-0 md:translate-x-1/4 -rotate-90 w-[160px] -mt-[8rem] md:rotate-180 md:-mt-[14rem]"
          ></img>
      </div>
      {/* <h2 className="font-bold text-2xl mt-3">شیوه پرداخت وجوهات:</h2> */}
      <ul className="text-lg mt-3 text-[#ccc] text-center flex flex-col gap-4 mx-auto sm:place-content-center sm:place-items-center">
        <li className="h-[200px] sm:h-[100px] relative w-[80vw] mr-2 rounded-[50px] border-[3px] border-green-900  bg-[#588157] flex place-content-center place-items-center py-5 pr-2 pl-[3rem] gap-2 md:w-[350px] lg:w-[600px]">
          <h3 className="w-[60px] w-5/12 sm:w-4/12 font-bold text-base border-l-2 pl-2 sm:border-none h-full grid place-content-center place-items-center">برای سفارش قربانی:</h3>
          {<div className="hidden sm:block sm:w-1/12 h-[100px] sm:bg-[#f5f5f5]"></div>}
          <ul className="text-sm w-7/12">
            <li>شماره کارت : 6274121173114133</li>
            <li>بانک سامان</li>
            <li>  سید محمدمهدی حسینی</li>
          </ul>
          <div className="absolute w-[90px] h-[90px] bg-green-900 top-50 left-0 -translate-x-2/4 rounded-full grid place-content-center place-items-center">
            <span className="text-5xl font-bold">01</span>
          </div>
        </li>
        <li className="h-[200px] sm:h-[100px] mr-2 relative w-[80vw] text-green-900  mr-2 rounded-[50px] border-[3px] border-green-900  bg-green-100 flex place-content-center place-items-center py-5 pr-2 pl-[3rem] gap-2 md:w-[350px] lg:w-[600px]">
          <h3 className="w-[60px] w-5/12 sm:w-4/12 font-bold text-sm border-l-2 pl-2 sm:border-none h-full grid place-content-center place-items-center">
            برای کمک به طرحهای آموزشی و ارتقاء تحصیلی :
          </h3>
          <div className="hidden sm:block sm:w-1/12 h-[100px] sm:bg-[#f5f5f5]"></div>
          <ul className="text-sm w-7/12">
            <li>شماره کارت : 5859831068241586</li>
            <li>بانک تجارت به نام سید محمدمهدی حسینی</li>
          </ul>
          <div className="absolute w-[90px] h-[90px] bg-green-900  top-50 left-0 -translate-x-2/4 rounded-full grid place-content-center place-items-center">
            <span className="text-5xl font-bold text-[#ccc]">02</span>
          </div>
        </li>
        <li className="h-[200px] sm:h-[100px] relative w-[80vw] mr-2 rounded-[50px] border-[3px] border-green-900  bg-[#588157] flex flex-col place-content-center place-items-center py-5 pr-2 pl-[3rem] gap-2 md:w-[350px] lg:w-[600px]">
          <h3 className="font-bold text-sm">
            شماره حساب به نام گروه جهادی شهید حججی 3101020448034
          </h3>
          <div className="h-[1px] bg-[#ccc] w-11/12"></div>
          <ul className="text-sm">
            <li>شماره شبا 110150000003101020448034 بانک سپه</li>
          </ul>
          <div className="absolute w-[90px] h-[90px] bg-green-900  top-50 left-0 -translate-x-2/4 rounded-full grid place-content-center place-items-center">
            <span className="text-5xl font-bold">03</span>
          </div>
        </li>
      </ul>
    </div>
    <h3 className="font-bold text-base text-green-900 mt-10 p-4 rounded-xl w-11/12 max-w-7xl mx-auto text-center mb-10">
      <span className="ml-2" style={{fontSize:'30px', color: '#012a4a'}}>&#8221;</span>
      تماس با گروه و عضویت در سامانه زرین‌پال از طریق لینک پرداخت که به
      صورت ماهانه برای افرادی که در این سامانه عضو هستند، ارسال می شود.
      <span className="mr-2" style={{fontSize:'30px', color: '#012a4a'}}>&#8220;</span>
    </h3>
    </>
  );
};
export default Help;
