import React from "react";
//moment
import moment from "moment-jalaali";
//SVG
import { ReactComponent as Calendar } from "./../../../../assets/svg/calendar.svg";
import { ReactComponent as Message } from "./../../../../assets/svg/messages-3.svg";
import { Link } from "react-router-dom";

const PReportListCard = ({ id, createdAt, image, title, description }) => {
  moment.loadPersian({ usePersianDigits: true });

  return (
    <div className="relative border-2 border-green-900 flex w-[250px] h-[218px] mr-[65px] sm:mx-auto sm:w-[320px] justify-between bg-[#f5f5f5] shadow-md rounded-lg col-span-12 md:col-span-6 md:w-[300px] md:h-[238px] lg:col-span-4 md:mr-[70px]">
      
      <div className="grid grid-rows-2 items-center content-center py-5 px-1 text-green-200 shadow-inner">
        <p className="flex items-center self-start">
          <Calendar className="w-12" />
          <span className="text-xs">{moment(createdAt).format("jDD jMMMM jYYYY")}</span>
        </p>
        <div className=" w-[58px] h-[116px] sm:w-[60px] sm:h-[120px] bg-[#f5f5f5] rounded-r-xl shadow-inner top-[50%] -right-[0] grid place-content-center place-items-center"
        style={{position:'absolute',transform:'translate(100%,-50%)'}}>
        </div>
        <img
        className="w-[90px] h-[90px] sm:w-[100px] sm:h-[100px] rounded-md object-fill top-[50%] right-[0]"
        style={{position:'absolute',transform:'translate(50%,-50%)'}}
        src={image}
        alt="news banner"
      />
        <p className="flex items-center self-end">
          <Message className="" />
          <span>دیدگاه</span>{" "}
        </p>
      </div>
      <div className="flex flex-col justify-end w-[170.83px] sm:w-[240.83px] md:w-[220.83px]">
        <p className="text-green-900 my-4 text-sm sm:text-base font-bold text-center line-clamp-2 overflow-hidden">
          {title}{" "}
        </p>
        <div className="w-5/6 h-[2px] bg-[#e5e5e5] mx-auto" ></div>
        <p className="text-center text-xs sm:text-sm line-clamp-3 overflow-hidden text-green-900 p-5">{description}</p>
        <div className="flex justify-center">
          <Link
            to={`/performance_report/${id}`}
            className="hover:text-green-900 place-self-end w-full mt-4 text-center hover:bg-[#f5f5f5] hover:shadow-inner border-none duration-200 border-2 border-[#415a77] bg-green-900 text-[#ccc] py-2 px-6 rounded-bl-lg"
          >
            ادامه مطلب
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PReportListCard;
