const LoadingNewsListCard = () => {
  return (
    <div className="px-3 py-5 shadow-md rounded-lg p-72 col-span-12 md:col-span-6 lg:col-span-3 mx-7 lg:mx-2">
      <p className="bg-gray-400 h-48 rounded-lg animate-pulse"></p>
      <div className="flex justify-between mt-5">
        <p className="w-16 h-3 bg-gray-400 animate-pulse rounded-sm"></p>
        <p className="w-16 h-3 bg-gray-400 animate-pulse rounded-sm"></p>
      </div>
      <p className="w-full h-7 bg-gray-400 animate-pulse rounded-sm my-3"></p>
      <div className="w-full h-16 bg-gray-400 animate-pulse rounded-sm my-3"></div>
      <div className="w-full h-10 bg-[#00ADB5] animate-pulse rounded-sm"></div>
    </div>
  );
};

export default LoadingNewsListCard;
