import React, { useState, useEffect } from "react";
//moment
import moment from "moment-jalaali";
//react-router-dom
import { useParams, Link } from "react-router-dom";

import { ReactComponent as Pdf } from "../../../assets/svg/pdf.svg";
//service
import { GetSinglePerformanceReport } from "../../../services/performanceReport";
//component
import LazyLoading from "../../../components/common/lazyLoading";
import LoadingSingleNews from "../../../components/pages/news/loadingSingleNews";
//SVG
import { ReactComponent as LeftArrow } from "./../../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "./../../../assets/svg/home-2.svg";

const SinglePerformanceReport = () => {
  moment.loadPersian({ usePersianDigits: true });

  const { id } = useParams();
  const [news, setNews] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUserCopy, setIsUserCopy] = useState(false);

  useEffect(() => {
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    asycnGetSingleNews();
  }, []);

  const asycnGetSingleNews = async () => {
    setIsLoading(true);
    try {
      const response = await GetSinglePerformanceReport({ id });
      //check response status
      if (response.status === 200) {
        setNews({
          ...response.data.data,
        });
      } else {
        //an error occure
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href).then(function () {
      setIsUserCopy(true);
      setTimeout(() => {
        setIsUserCopy(false);
      }, [5000]);
    });
  };

  const loadingCard = () => {
    return <LoadingSingleNews />;
  };

  return (
    <LazyLoading isLoading={isLoading} card={loadingCard} numberRepetation={1}>
      <div className="flex items-center gap-2 sm:gap-3 mr-4 sm:mr-0">
        <Link to={"/"}>
          <Home />
        </Link>
        <LeftArrow />
        <Link to={"/performance_report"}>
          <span className="text-sm sm:text-lg">کزارش عملکرد</span>
        </Link>
        <LeftArrow />
        <span className="text-[#B0B9BE] text-[10px] sm:text-lg ">
          {news.slug}{" "}
        </span>
      </div>
      <div className="flex items-center gap-3 mt-10 mr-4 sm:mr-0">
        <span className="text-[#334155] font-bold sm:text-3xl text-l">
          {news.title}{" "}
        </span>
      </div>
      <div className="flex gap-3 my-6 text-[#475466] text-sm sm:text-md mr-4 sm:mr-0 ">
        <div className="border-l-2 border-[#B0B9BE] sm:pl-3 pl-1">
          {moment(news.created_at).format("jDD jMMMM jYYYY , HH:mm")}
        </div>
        <div className=" sm:pl-3 pl-1 ">
          <span>کد گزارش:</span>
          <span>588325</span>
        </div>
      </div>
      <div className=" bg-[#FFF] rounded-md shadow-md p-5">
        <div>
          <img
            className="w-full sm:w-3/4 h-[500px] rounded-md mx-auto"
            src={news.image}
            alt="news"
          />
        </div>
        <div className="mt-8" dangerouslySetInnerHTML={{ __html: news.caption }} />
        <div className="m-10 card bg-white shadow-xl p-5 ">
          <h1 className="font-bold text-xl mb-5 "> فایل پیوستی ها</h1>
          <a
            className="cursor-pointer flex gap-3 items-center hover:text-blue-600"
            title="download" href={news.attachment}
          >
            <Pdf className="w-8 h-8" />
               فایل پیوست.pdf
          </a>
        </div>
        <div className="py-8 px-6 text-lg sm:text-xl ">
          {/* <p className="mb-8 text-[#475466] sm:leading-loose  ">
            {news.body}
          </p> */}
          {/* <div className="flex flex-col gap-6 text-lg sm:text-xl">
            <span className="text-[#112D4E] ">بیشتر بخوانید:</span>
            <span className="text-[#3F72AF]">
              برگزاری بیست‌وچهارمین جلسه کلاس آموزش مدیریت اسلامی
            </span>
            <span className="text-[#3F72AF]">
              نخستین دوره اعزام دبیر به بشاگرد برای برگزاری آموزش حضوری در سال
              تحصیلی ۱۴۰۱-۱۴۰۲
            </span>
            <span className="text-[#3F72AF]">
              بزرگداشت روز دانشجو با حضور دانشجویان بشاگردی شاغل به تحصیل در
              تهران و دانشجویان فعال عضو گروه جهادی
            </span>
          </div> */}
        </div>
      </div>
    </LazyLoading>
  );
};

export default SinglePerformanceReport;
