// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
// eslint-disable-next-line
import "swiper/css/bundle";
// import required modules
import { EffectCards, Navigation } from "swiper";
import EduImage from "./../../../../assets/image/edu.png";
import workImage from "./../../../../assets/image/work.png";
import behImage from "./../../../../assets/image/behImage.png";
import HelpImage from "./../../../../assets/image/HelpImage.png";
import {
  CharityServiceSwiperBtnNext,
  CharityServiceSwiperBtnPrev,
} from "../../../common/swiperBtn";

const list = [
  {
    title: "حیطه آموزش ",
    desc: `   کمک به ارتقا تحصیلی و تحقق عدالت آموزشی، کمک به پیشرفت تحصیلی
    دانش آموزان منطقه کم برخوردار بشاگرد`,
    img: EduImage,
  },
  {
    title: "حیطه اشتغال زایی",
    desc: `تلاش برای ایجاد اشتغال پایدار در منطقه بشاگرد، این گروه تلاش میکند
    تا به جای کمک مالی مستقیم با تکیه بر توانایی ها و ظرفیت های موجود
    در ارائه آموزش های لازم جهت ایجاد اشتغال پایدار، گامی در جهت بهبود
    سطح زندگی و افزایش رفاه اجتماعی-اقتصادی اهالی بشاگرد بردارد`,
    img: workImage,
  },
  {
    title: "حیطه بهداشت و درمان",
    desc: `  تلاش برای ترویج سبک زندگی سالم ارتقاء سطح بهداشت عمومی در جهت
    پیشگیری از بروز بیماری
    `,
    img: behImage,
  },
  {
    title: "کمک به اجرای طرح های آبخیزداری و احداث آب بند",
    desc: ` این گروه با کمک به احداث آب بند و اجرای طرح آبخیزداری در بشاگرد
    با مهار سیلابهای ناشی از بارشهای فصلی علاوه بر کمک به تامین آب
    شرب به احیای اشتغال در زمینه کشاورزی و دامداری در منطقه نیز کمک
    میکند.`,
    img: HelpImage,
  },
];

const CharityService = () => {
  return (
    <div className="-mt-[30px] sm:-mt-[100px] overflow-hidden">
      <div className="h-[30px] sm:h-[100px]" style={{ overflow: "hidden" }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-1.92,2.47 C486.23,145.16 12.75,148.12 504.85,-0.49 L510.49,158.94 L-9.25,163.86 Z"
            style={{ stroke: "none", fill: "#F5F5F5" }}
          ></path>
        </svg>
      </div>
      <div className="mt-4 flex flex-col items-center justify-center gap-3 mb-10">
        <span className="place-self-center  text-[#3a5a40] font-semibold text-4xl">
          خدمات
        </span>
        <span className="text-xl text-center text-green-100">
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
        </span>
      </div>
      <div className="flex flex-col md:grid md:grid-cols-2 place-content-center place-items-center gap-4 lg:w-2/3 lg:gap-y-16 mx-auto">
        {list.map((item, index) => (
          <div
            key={index}
            className="flex flex-col content-center text-center place-items-center w-64 shadow-lg shadow-[#889c88] mx-auto rounded-xl h-64 p-2 pt-12 mt-12"
            style={{ position: "relative" }}
          >
            <img
              src={item.img}
              className="absolute top-0 -translate-y-1/2 right-[50%] translate-x-1/2 bg-[#f5f5f5] p-2 w-20 rounded-full shadow-md shadow-green-200 grid place-content-center place-items-center"
            />
            <h1 className="px-4 pt-3 font-bold gap-3 place-self-center text-green-900 text-xl text-center">
              {item.title}
            </h1>
            <p className="px-2 py-3 text-green-100 text-sm">{item.desc}</p>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center mt-4 gap-x-10 h-14"></div>
    </div>
  );
};

export default CharityService;
