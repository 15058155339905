import React from "react";
import { Link } from "react-router-dom";

const AuthoritiesCard = ({
  id,
  last_name,
  first_name,
  image,
  employee_category,
}) => {
  return (
    <Link
     to={`/employees/${id}`}>
      <div className="flex flex-col items-center">
        <img
          className="w-60 h-60 rounded-full object-cover"
          src={image}
          alt="authorite"
        />
        <p className="text-center font-bold my-3">
          {first_name + " " + last_name}
        </p>
        <p className="text-sm text-center">{employee_category}</p>
      </div>
    </Link>
  );
};

export default AuthoritiesCard;
