import React from "react";
import { Link } from "react-router-dom";
import {ReactComponent as Arrow} from './../../../../../assets/svg/arrow.svg'

const CommitteesCard = ({ id, image, title, description }) => {
  return (
    <Link
      to={`/committee/${id}`}
      className="relative border-2 w-44 h-44 border-green-900 p-2 rounded-2xl mx-auto flex flex-col place-content-center place-items-center"
    >
      <img
        className=" rounded-2xl object-cover w-2/6"
        src={image}
        alt="commiteImage"
      />
      <div className="p-2">
        <p className="pr-2 text-lg text-green-900 font-bold text-center line-clamp-2 overflow-hidden">
          {title}
        </p>
        <button
        className="absolute bottom-0 left-0 border border-white self-end bg-green-200 text-[#F5F5F5] hover:scale-105 px-3 py-2 grid place-content-center place-item-center rounded-tr-xl rounded-bl-xl mt-6">
          <Arrow className="text-white"/>
        </button>
      </div>
    </Link>
  );
};

export default CommitteesCard;
