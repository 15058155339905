import React, { useEffect, useState } from "react";

//Components
import Pagination from "../../components/common/pagination";
import LoadingNewsListCard from "../../components/pages/news/loadingNewsListCard";
import LazyLoading from "../../components/common/lazyLoading";
//SVG
import { ReactComponent as LeftArrow } from "../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "../../assets/svg/home-2.svg";
import { ReactComponent as DotsGroup } from "../../assets/svg/Group.svg";

//service
import { GetAllCommittee } from "../../services/committee";
import CommitteesCard from "../../components/pages/home/committees/committeesCard";

const CommitteeList = () => {
  const [committees, setCommittees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    asyncGetCommitteesList();
  }, []);

  const asyncGetCommitteesList = async () => {
    setIsLoading(true);
    try {
      const response = await GetAllCommittee();

      //check repsonse status
      if (response.status === 200) {
        setCommittees([...response.data.data]);
      } else {
        //error occure
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const generaList = () => {
    return (
      Array.isArray(committees) &&
      committees.length > 0 &&
      committees.map((committeesItem, index) => (
        <CommitteesCard
          key={committeesItem.id}
          id={committeesItem.id}
          image={committeesItem.image}
          title={committeesItem.name}
          description={committeesItem.short}
        />
      ))
    );
  };

  // console.log("List : ", news);

  const loadingCard = () => {
    return <LoadingNewsListCard />;
  };

  return (
    <div>
      <div className="flex items-center gap-3 -mt-[8rem] mr-7">
        <Home />
        <LeftArrow />
        <span>کمیته ها</span>
      </div>
      <div className="flex items-center gap-3 mr-7 mt-10 mb-16">
        <DotsGroup />
        <span className="text-[#334155] font-bold text-3xl">لیست کمیته ها</span>
      </div>
      <div className="flex flex-wrap gap-y-10">
        <LazyLoading
          card={loadingCard}
          isLoading={isLoading}
          numberRepetation={6}
        >
          {generaList()}
        </LazyLoading>
      </div>
      <div className="mt-10 mx-3">{/* <Pagination /> */}</div>
    </div>
  );
};

export default CommitteeList;
