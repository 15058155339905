import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

//react-router-dom
import { useNavigate } from "react-router-dom";

//services
import { PostUserData , GetCommitteeList } from "../../../../../services/auth";

//component
import CharityInput from "../../../../common/input/charityInput";
import LoadingBtn from "../../../../common/loadingBtn";
import LocationsList from "./locations";
// SVG
import { ReactComponent as Back } from "./../../../../../assets/svg/Vector.svg";

//redux
import { useSelector } from "react-redux";

//cookie
import { useCookies } from "react-cookie";

const PersonalInformatioon = () => {
  const [cookies, setCookies] = useCookies(["token"]);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state);


  const [dataSchema, setDataSchema] = useState({
    firstName: "",
    lastName: "",
    birthDay: "",
    email: "",
    nationalCode:"",
    userType:0,
    city:"",
    alms:"",
    committees:0
  });
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [committeesList , setCommitteesList] = useState([])

  useEffect(() => {
    getCommitteesList()
  },[])

  const getCommitteesList = async () => {
    try {
      const response = await GetCommitteeList();

      if(response.status === 200){
        setCommitteesList([...response.data.data])
      }else{
        toast.error("در دریافت اطلاعات کمتیه ها دچار مشکل شدیم")
      }

    } catch (error) {
      console.log("error : " , error);
    }
  }

  const onDataSchemaHandler = (target, value) => {
    setDataSchema({
      ...dataSchema,
      [target]: value,
    });
  };

  const asyncPostUserData = async () => {
    setError({});


    if(dataSchema.userType === 1){
      if(dataSchema.alms.trim() === ""){
        setError({
          alms:"مقدار کمک مالی را باید وارد کنید"
        });
        return
      }
    }

    const { firstName, birthDay, email, lastName , nationalCode,alms,committees ,userType  ,city} = dataSchema;
    const phoneNumber = user.phoneNumber;

    setIsLoading(true);

    try {

      const response = await PostUserData({
        firstName,
        birthDay,
        email,
        lastName,
        phoneNumber,
        nationalCode,
        alms,
        committees,
        userType,
        city
      });
  
      if (response.status === 200) {
        toast.success("اطلاعات با موفقیت ثبت شد");
        setCookies("token", response.data.authorisation.token);
        navigate("/");
      } else {
        //error occurre
        toast.error("ثبت نام با مشکل مواجه شد")
        console.log("response : ", response);
      }
    } catch (error) {
      console.log("error : " , error)
    }

    setIsLoading(false);
  };

  console.log("dataSchema : " , dataSchema)

  return (
    <div className="text-sm lg:text-base">
      <button className="px-3 py-2 rounded bg-[#F5F6FA]">
        <Back />
      </button>
      <span className="font-bold text-xl flex flex-row justify-center mb-5">
        اطلاعات فردی
      </span>
      <div className="flex flex-col items-start gap-y-2">
        <CharityInput
          error={error.first_name}
          label="نام"
          onChangeHandler={onDataSchemaHandler}
          placeholder="نام خود را وارد کنید"
          target={"firstName"}
          type="text"
        />
        <CharityInput
          error={error.last_name}
          label="نام خانوادگی"
          onChangeHandler={onDataSchemaHandler}
          placeholder="نام خود را وارد کنید"
          target={"lastName"}
          type="text"
        />

        <CharityInput
          error={error.email}
          label="ایمیل (اختیاری)"
          onChangeHandler={onDataSchemaHandler}
          placeholder="ایمیل خود را وارد کنید"
          target={"email"}
          type="email"
        />

        <CharityInput
          error={error.national_code}
          label="کد ملی (اختیاری)"
          onChangeHandler={onDataSchemaHandler}
          placeholder="کد ملی خود را وارد کنید"
          target={"nationalCode"}
          type="text"
        />

        <CharityInput
          onChangeHandler={onDataSchemaHandler}
          target={"birthDay"}
          label={"تاریخ تولد"}
          placeholder="تاریخ تولد خود را وارد کنید"
          type={"date"}
          error={error.birthday}
        />

        <LocationsList dataSchema={dataSchema} onDataSchemaHandler={onDataSchemaHandler} />

        <div className="my-2 flex flex-col items-start justify-start w-full">
          <span>نوع همکاری خود را انخاب کنید</span>
          <select onChange={(e) => onDataSchemaHandler("userType" , +e.target.value)} className="w-full border-2 border-[#9b9b9b] p-2 rounded-md">
            <option value={0}>
              نیروی انسانی
            </option>
            <option value={1}>
              نیروی مالی
            </option>
          </select>
        </div>

        {
        dataSchema.userType === 0 ? 
          <div className="my-2 flex flex-col items-start justify-start w-full">
            <span>نوع کمیته ی خود را انتخاب کنید</span>
            <select onChange={(e) => onDataSchemaHandler("committees" , +e.target.value)} className="w-full border-2 border-[#9b9b9b] p-2 rounded-md">
              {
                committeesList.map((singleCommittee) => 
                    <option value={singleCommittee.id} key={singleCommittee.id}>
                      {singleCommittee.name}
                    </option>
                )
              }
            </select>
          </div>
        :
          <CharityInput
            onChangeHandler={onDataSchemaHandler}
            target={"alms"}
            label={"کمک مالی"}
            placeholder="مقدار کمک مالی خود را وارد کنید"
            type={"text"}
            error={error.alms}
          />
        }

      </div>
      <LoadingBtn
        className={"bg-[#31ADE4]"}
        action={asyncPostUserData}
        isLoading={isLoading}
        text="ثبت نام"
      />
    </div>
  );
};

export default PersonalInformatioon;
