import useFetch from "./../hooks/useFetch";


export async function GetProvincesListHttp() {
    const apiCall = await useFetch().get(`provinces`);
    return apiCall;
}


export async function GetCitiesListHttp({provinceId}) {
    const apiCall = await useFetch().get(`cities/${provinceId}`);
    return apiCall;
}

  