import React, { useEffect, useState } from "react";

//Components
import LoadingNewsListCard from "../../components/pages/news/loadingNewsListCard";
import LazyLoading from "../../components/common/lazyLoading";
//SVG
import { ReactComponent as LeftArrow } from "../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "../../assets/svg/home-2.svg";
import { ReactComponent as DotsGroup } from "../../assets/svg/Group.svg";

//service
import { GetAllAuthorities } from "../../services/authorities";
import AuthoritiesCard from "../../components/pages/home/authorities/authoritiesCard";

const EmployeesList = () => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    asyncGetEmployeesList();
  }, []);

  const asyncGetEmployeesList = async () => {
    setIsLoading(true);
    try {
      const response = await GetAllAuthorities();

      //check repsonse status
      if (response.status === 200) {
        setEmployees([...response.data.data]);
      } else {
        //error occure
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const generaList = () => {
    return (
      Array.isArray(employees) &&
      employees.length > 0 &&
      employees.map((employeeItem, index) => (
        <AuthoritiesCard
          key={employeeItem.id}
          id={employeeItem.id}
          image={employeeItem.image}
          last_name={employeeItem.last_name}
          first_name={employeeItem.first_name}
          employee_category={employeeItem.employee_category}
        />
      ))
    );
  };

  // console.log("List : ", news);

  const loadingCard = () => {
    return <LoadingNewsListCard />;
  };

  return (
    <div>
      <div className="flex items-center gap-3 mr-7">
        <Home />
        <LeftArrow />
        <span> مسولین </span>
      </div>
      <div className="flex items-center gap-3 mr-7 mt-10 mb-16">
        <DotsGroup />
        <span className="text-[#334155] font-bold text-3xl">لیست مسولین </span>
      </div>
      <div className="grid grid-cols-3 gap-y-10">
        <LazyLoading
          card={loadingCard}
          isLoading={isLoading}
          numberRepetation={6}
        >
          {generaList()}
        </LazyLoading>
      </div>
      <div className="mt-10 mx-3">{/* <Pagination /> */}</div>
    </div>
  );
};

export default EmployeesList;
