import React, { useState, useEffect } from "react";

//service
import { GetEducationCoursesList } from "../../services/educationCourse";

//SVG
import { ReactComponent as PathIcon } from "./../../assets/svg/pathIcon.svg";

//Components
import EducationCard from "../../components/pages/educationCourses/educationCard";
import LazyLoading from "../../components/common/lazyLoading";
import LoadingEducationCard from "../../components/pages/educationCourses/loadingEducationCard";
// import Pagination from "../../components/common/pagination";

import HeaderFilter from "../../components/pages/educationCourses/educationCourseList/filterCourseList/headerFilter";
import AsideFilter from "../../components/pages/educationCourses/educationCourseList/filterCourseList/asideFilter";
import { useLocation } from "react-router-dom";
//SVG
import { ReactComponent as LeftArrow } from "./../../assets/svg/leftarrow-news.svg";


const EducationCourses = () => {
  const [course, setCourse] = useState({
    data: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    asyncGetCourseList();
    //redirect to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    //on search for course after filtering data
    if (location.search.length > 0) {
      asyncGetCourseList(location.search.substring(1));
    }
  }, [location]);

  const asyncGetCourseList = async (filter) => {
    setIsLoading(true);
    try {
      const response = await GetEducationCoursesList({
        filter,
      });
      //check response status
      if (response.status === 200) {
        //get data successfully
        setCourse(response.data);
      } else {
        //An error occurred
        console.log("response : ", response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const returnLoadingCard = () => {
    return <LoadingEducationCard />;
  };

  return (
    <div className="sm:w-full lg:mx-auto mx-4 sm:mx-0 -mt-[8rem]">
      <div className="flex items-center gap-2 my-5 lg:my-10 text-sm lg:text-xl">
        <PathIcon />
        <span>خانه </span>
        <LeftArrow />
        <span>
           دوره های آموزشی</span>
      </div>
      <HeaderFilter />
      <div className="lg:flex mb-10">
        {/* <AsideFilter maxValue={course.max_price} /> */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-x-5 gap-y-5 mt-5 w-full">
          <LazyLoading
            card={returnLoadingCard}
            isLoading={isLoading}
            numberRepetation={8}
          >
            {course.data && course.data.length === 0 ? (
              <span className="text-xl font-semibold col-span-12 text-center mt-5">
                دوره ای برای نمایش وجود ندارد ...
              </span>
            ) : (
              course.data.map((card, index) => (
                
                <EducationCard
                  key={index}
                  id={card.id}
                  author={card.author}
                  banner={card.banner}
                  title={card.title}
                  price={card.price}
                  discount={card.discount}
                  body={card.body}
                  
                />
              ))
            )}
          </LazyLoading>
        </div>
      </div>
      {/* <Pagination /> */}
    </div>
  );
};

export default EducationCourses;
