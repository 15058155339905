import React, { useState } from "react";

// import Swiper core and required modules
import { Autoplay, A11y } from "swiper";

import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import backgroundImage from "./../../../../assets/image/The-Foundation-Course-1.png";

//componet
import { GetTitleText } from "../../../../services/title";
import { useEffect } from "react";
const Hero = () => {
  const [images, setImages] = useState();
  const [title, setTitle] = useState();

  useEffect(() => {
    httpGetTitleList();
  }, []);

  const httpGetTitleList = async () => {
    try {
      const response = await GetTitleText();

      if (response.status === 200) {
        setTitle({
          ...response.data.data,
        });
        // console.log(title);
      }
    } catch (error) {
      console.log("error in get committee list : ", error);
    }
  };
  return (
    <>
      <div
        className="grid h-screen bg-no-repeat bg-center bg-cover items-center m-auto -mt-40"
        style={{
          backgroundImage: `linear-gradient(rgba(27,58, 40, 0.2), rgba(27, 58, 40, 0.2), rgba(27,58, 40, 0.6)), url(${backgroundImage})`,
        }}
      >
        <div
          className="grid m-auto place-content-center auto-rows-auto place-items-center order-2"
        >
          <p
            className="font-semibold text-3xl my-5 md:text-4xl xl:text-5xl text-[#dad7cd] text-center"
            style={{ textShadow: "0px 0px 4px black" }}
          >
            گروه جهادی شهید حججی
          </p>
          <p
            style={{ textAlign: "center", textShadow: "0px 0px 4px black" }}
            className="leading-loose w-5/6 md:w-3/5 text-center text-sm sm:text-base md:text-xl lg:text-right xl:text-2xl xl:leading-10 text-[#dad7cd]"
          >
            {
              <span
                style={{
                  fontSize: "20px",
                  color: "#3C7B00",
                  textShadow: "0px 0px 5px #213826",
                }}
              >
                &#8221;
              </span>
            }
            {title?.short_title}
            {
              <span
                style={{
                  fontSize: "20px",
                  color: "#3C7B00",
                  textShadow: "0px 0px 5px #213826",
                }}
              >
                &#8220;
              </span>
            }
          </p>
          <div className="pb-4 sm:pb-0 sm:mt-5 xl:mt-12 ">
            <Link to={"/actions"}>
              <button className="border-2 border-green-800 rounded-xl bg-green-900 hover:scale-105 duration-200 text-[#dad7cd] focus:outline-none font-mediumtext-sm px-5 py-2.5 me-2 my-2">
                بیشتر بخوانید
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
