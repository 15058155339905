import React from "react";
//SVG
import { ReactComponent as Pdf } from "../../assets/svg/pdf.svg";
const Actions = () => {
  return (
    <>
    <div className="bg-[#f5f5f5] -mt-40">
      <div className="max-w-6xl mx-4 rounded-xl m-5 card text-green-900 shadow-xl p-5"
      >
        <h1 className="font-bold text-xl lg:text-2xl mb-5 ">گروه جهادی شهید حججی</h1>
        <p className="text-sm lg:text-base text-green-200">
          گروه جهادی شهید حججی یک سازمان غیرانتفاعی و مردم‌نهاد است که در سال
          ۱۳۹۶ به صورت رسمی با شماره ۳۹۵۶۸۰۵۰۱۴۰۰۱ با هدف فعالیت جهت محرومیت
          زدایی از مناطق محروم و به صورت خاص منطقه کم‌برخوردار بشاگرد ،به ثبت
          رسیده است . این گروه به صورت عمده در چهار حیطه مختلف فعالیت می‌کند:
        </p>
        <ul className="text-sm list-disc p-5 mt-3 lg:text-base text-green-200">
          <li>
            حیطه آموزش: کمک به ارتقاء تحصیلی و تحقق عدالت آموزشی، کمک به پیشرفت
            تحصیلی دانش‌آموزان منطقه کم‌برخوردار بشاگرد
          </li>
          <li>
            حیطه اشتغال زایی: تلاش برای ایجاد اشتغال پایدار در منطقه بشاگرد. این
            گروه تلاش می‌کند تا به جای کمک مالی مستقیم با تکیه بر توانایی‌ها و
            ظرفیتهای موجود در منطقه و ارائه آموزشهای لازم جهت ایجاد اشتغال
            پایدار، گامی در جهت بهبود سطح زندگی و افزایش رفاه اجتماعی-اقتصادی
            اهالی بشاگرد بردارد.
          </li>
          <li>
            بخش بهداشت و درمان: تلاش برای ترویج سبک زندگی سالم و ارتقاء سطح
            بهداشت عمومی در جهت پیشگیری از بروز بیماری
          </li>
          <li>
            کمک به اجرای طرح های آبخیزداری و احداث آب‌بند: این گروه با کمک به
            احداث آب‌بند و اجرای طرح ‌های آبخیزداری در بشاگرد با مهار سیلابهای
            ناشی از بارشهای فصلی علاوه بر کمک به تأمین آب شرب به احیای اشتغال در
            زمینه کشاورزی و دامداری در منطقه نیز کمک می‌کند.
          </li>
        </ul>
      </div>
      <div className="m-5 card shadow-xl p-5 bg-gradient-to-br from-green-900 from-10% green-800 via-30% to-[#004b23] to-90% rounded-xl text-[#ccc] max-w-6xl mx-4">
        <h1 className="font-bold text-xl mb-5 "> کمیته ها</h1>
        <a
          className="cursor-pointer flex gap-3 items-center hover:text-[#caf0f8]"
          title="download" href="https://api.inabadi.ir/LinkDownload/duties/sharhe_vazaef_komiteha_1401.pdf"
        >
          <Pdf className="w-8 h-8" />
          آشنایی با کمیته ها.pdf
        </a>
      </div>
      </div>
      </>
  );
};
export default Actions;
