import React from "react";

function convertToLocalString(number) {
  return number.toLocaleString("fa");
}

const TimeConverter = ({ seconds, className }) => {
  return (
    <>
      <div className={`${className}`}>
        <span>
          {Math.floor(seconds / 3600) < 10
            ? convertToLocalString(0) +
              convertToLocalString(Math.floor(seconds / 3600))
            : convertToLocalString(Math.floor(seconds / 3600))}
        </span>
        :
        <span>
          {Math.floor((seconds % 3600) / 60) < 10
            ? convertToLocalString(0) +
              convertToLocalString(Math.floor((seconds % 3600) / 60))
            : convertToLocalString(Math.floor((seconds % 3600) / 60))}
        </span>
        :
        <span>
          {Math.floor((seconds % 3600) % 60) < 10
            ? convertToLocalString(0) +
              convertToLocalString(Math.floor((seconds % 3600) % 60))
            : convertToLocalString(Math.floor((seconds % 3600) % 60))}
        </span>
      </div>
    </>
  );
};

export default TimeConverter;
