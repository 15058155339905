import React, { useState } from "react";

//component
import PersonalInformatioon from "./personalInformation";
import SignUpCOde from "./signUpCode";
import SignUpPhone from "./signUpPhone";

const SignUp = () => {
  const [step, setStep] = useState(0);

  const stepHandler = (reqCondition = "forward") => {
    if (reqCondition === "forward") {
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  };

  return (
    <div className="xl:col-span-1 lg:col-span-3 md:col-span-4 rounded-lg h-fit py-5 lg:px-10 px-5">
      {step === 0 ? (
        <SignUpPhone stepForwardHandler={stepHandler} />
      ) : step === 1 ? (
        <SignUpCOde
          stepForwardHandler={stepHandler}
          stepBackwardHandler={() => stepHandler("backward")}
        />
      ) : (
        <PersonalInformatioon
          stepBackwardHandler={() => stepHandler("backward")}
        />
      )}
    </div>
  );
};

export default SignUp;
