import React, { useState, useEffect } from "react";

//react router dom
import { useParams, Link } from "react-router-dom";

//service

//SVG
import { ReactComponent as LeftArrow } from "./../../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "./../../../assets/svg/home-2.svg";
import { GetSingelEmployee } from "../../../services/authorities";

const SingleEmployee = () => {
  const { id } = useParams();

  //data
  const [employee, setEmployee] = useState({});

  useEffect(() => {
    httpGetEmployeeList();
  }, []);

  const httpGetEmployeeList = async () => {
    try {
      const response = await GetSingelEmployee({ id });

      if (response.status === 200) {
        setEmployee({
          ...response.data.data,
        });
      }
    } catch (error) {
      console.log("error in get employee list : ", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start gap-5">
      <div className="flex items-center justify-start gap-2 sm:gap-3 w-full">
        <Link to={"/"}>
          <Home />
        </Link>
        <LeftArrow />
        <Link to={"/employees"}>
          <span className="text-sm sm:text-lg">نام مسول</span>
        </Link>
        <LeftArrow />
        <span className="text-[#B0B9BE] text-[10px] sm:text-lg ">
          {employee.first_name + " " + employee.last_name}{" "}
        </span>
      </div>
      <img
        className=" h-[500px] w-[400px] rounded-md object-fill"
        src={employee.image}
        alt="banner"
      />
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-col items-start gap-1">
          <span className="text-xl font-medium">مسولیت</span>
          <span className="text-lg">{employee.employee_category}</span>
        </div>
        <div className="flex flex-col items-start gap-1">
          <span className="text-xl font-medium">درباره مسول</span>
          <p
            className="text-lg"
            dangerouslySetInnerHTML={{ __html: employee.caption }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default SingleEmployee;
