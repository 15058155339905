import useFetch from "./../hooks/useFetch";
const endPoint = "employees";

export async function GetAllAuthorities() {
  const callApi = await useFetch().get(endPoint);
  return callApi;
}

export async function GetSingelEmployee({ id }) {
  const callApi = await useFetch().get(`employees/${id}`);
  return callApi;
}
