import React, { useState, useEffect } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import {
  NewsSwiperBtnNext,
  NewsSwiperBtnPrev,
} from "../../../common/swiperBtn";
// Import Swiper styles
import "swiper/css";

//component
import SingleNews from "./singleNews";
import LoadingLayout from "../../../common/loadingLayout";
//services
import { GetAllNews } from "../../../../services/news";

//SVG
import { ReactComponent as ArrowLeft } from "./../../../../assets/svg/arrowLeft.svg";
import { ReactComponent as LeftArrow } from "../../../../assets/svg/leftarrow-news.svg";
import { Link } from "react-router-dom";

const HomeNews = () => {
  const [news, setNews] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(6);

  useEffect(() => {
    //request data in start of pages
    asyncGetAllUsers();
  }, []);

  const asyncGetAllUsers = async () => {
    try {
      //start loading in layout
      setIsLoading(true);
      const response = await GetAllNews();
      if (response.status === 200) {
        setNews(response.data.data);
        console.log(response.data.data);
      }
      //fetch data stop loading
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(news);
  //check list length , length should be bigger than 2
  if (Array.isArray(news) && news.length <= 2) {
    //list is empty , return null instaead of data
    return null;
  }

  return (
    <div className="my-32 overflow-hidden flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center w-full">
        <span className="place-self-center text-green-900 font-semibold text-4xl p-2">اخبار</span>
        <div className="flex place-content-center place-items-center w-full text-xl mb-4 text-green-100 md:text-lg lg:text-xl">
          {<span>جدیدترین اخبار و اطلاعیه‌ها</span>}
          
        </div>
      </div>

      <div className="relative w-[1250px]">
        <LoadingLayout isLoading={isLoading}>
          <>

            <Swiper
              modules={[Navigation, A11y]}
              spaceBetween={20}
              slidesPerView={3}
              loop
              navigation={{
                nextEl: ".News-swiper-next-btn",
                prevEl: ".News-swiper-prev-btn",
              }}
              onSlideChange={(index) => {
                if (index.realIndex + 1 === news.length) {
                  setActiveIndex(0);
                } else {
                  setActiveIndex(index.realIndex + 1);
                }
              }}
              allowTouchMove
            >
              {Array.isArray(news) &&
                news.map((item, index) => (
                  <SwiperSlide key={index}>
                    <SingleNews
                      activeIndex={activeIndex}
                      data={item}
                      index={index}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </>
        </LoadingLayout>

      </div>
      <span className="px-6 py-3 mx-2 rounded-xl text-green-800 mb-5 font-semibold text-lg text-center"
        >
        {Array.isArray(news) && news[activeIndex] && news[activeIndex].title}
      </span>
      <div className="flex">
        <NewsSwiperBtnNext swiperClass="News-swiper-next-btn" />
        <NewsSwiperBtnPrev swiperClass="News-swiper-prev-btn" />
      </div>
      <Link to={"/news"}>
        <div className="text-sm md:text-base flex items-center gap-x-2 mx-auto place-content-center place-item-center mt-7 ">
          <p className="text-2xl text-green-100">مشاهده همه</p>
          <div className="animate-ping">
            <LeftArrow />
          </div>
        </div>
      </Link>
      {
        //description of active news in slider (center news in swiper)
        // <span className="w-full sm:w-1/3 block text-sm md:text-lg mx-auto text-center text-[#25282F] line-clamp-2 overflow-hidden">
        //   {Array.isArray(news) &&
        //     news[activeIndex] &&
        //     news[activeIndex].description}
        // </span>
      }
    </div>
  );
};

export default HomeNews;
