//SVG
import { ReactComponent as LeftArrow } from "./../../../../assets/svg/leftarrow-news.svg";
import { ReactComponent as Home } from "./../../../../assets/svg/home-2.svg";
const LoadingSingleNews = () => {
  return (
    <>
      <div className="flex items-center gap-2 sm:gap-3 mr-4 sm:mr-0">
        <Home />
        <LeftArrow />
        <span className="text-sm sm:text-lg">اخبار</span>
        <LeftArrow />
        <span className="bg-gray-400 h-5 w-44 rounded-sm animate-pulse"></span>
      </div>
      <div className="bg-gray-400 h-10 w-3/4 my-5 rounded-sm animate-pulse"></div>
      <div className="flex gap-3 my-6 text-[#475466] text-sm sm:text-md mr-4 sm:mr-0 ">
        <div className="bg-gray-400 h-5 w-32 rounded-sm animate-pulse"></div>
        <div className="bg-gray-400 h-5 w-32 rounded-sm animate-pulse"></div>
        <div className="bg-gray-400 h-5 w-32 rounded-sm animate-pulse"></div>
      </div>
      <div className=" bg-[#FFF] rounded-md shadow-md ">
        <div className="w-full h-80 bg-gray-400 rounded-sm animate-pulse"></div>
        <div className="py-8 px-6 text-lg sm:text-xl ">
          <div className="w-full h-32 bg-gray-400 rounded-sm animate-pulse"></div>
          <div className="w-3/4 h-10 bg-gray-400 rounded-b-sm animate-pulse"></div>{" "}
        </div>
      </div>
      <div className="bg-[#FFF] my-14 p-7 rounded-md shadow-md ">
        <div className="flex flex-row border-b-2  p-2  border-[#B0B9BE]">
          <span className="text-[#B0B9BE] text-xl ml-2">#</span>
          <span className="text-[#000]">برچسب ها</span>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5 mt-5">
          <span className="bg-gray-400 h-8 rounded-sm animate-pulse"></span>
          <span className="bg-gray-400 h-8 rounded-sm animate-pulse"></span>
          <span className="bg-gray-400 h-8 rounded-sm animate-pulse"></span>
          <span className="bg-gray-400 h-8 rounded-sm animate-pulse"></span>
        </div>
      </div>
    </>
  );
};

export default LoadingSingleNews;
